
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Main from "./components/Main";
import RedirectHandler from "./components/RedirectHandler";

function App() {
  return (
  <>
  <RedirectHandler/>
  <Main/>
  </>
  );
}



export default App;
