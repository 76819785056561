import React from "react";
import { Navigate } from "react-router-dom";

// ProtectedRoute component that checks if the user is authenticated
const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  if (!isAuthenticated) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/admin_tetfi/login" />;
  }

  // Render the children (protected component) if authenticated
  return children;
};

export default ProtectedRoute;
