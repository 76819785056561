import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../shared/LoadingSpinner';

import "./admin.styles.css";
import LogoutIcon from '../Dashboard/icons/LogoutIcon';

function AdminDashboard() {
const navigate = useNavigate();
const[users,setUsers]  = useState({})
const [loading,setLoading] = useState(false)


const handleLogout = () => {
  localStorage.removeItem("isAuthenticated"); // Clear the authentication flag
  navigate("/admin_tetfi/login"); // Redirect to the login page
};


useEffect(() => {
  const fetchInvestments = async () => {
    setLoading(true)
    const url = process.env.REACT_APP_ADMIN_USERS;
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    };
    try {
      const res = await fetch(url, params);
      const resObj = await res.json();
      setUsers(resObj?.data || {});
      setLoading(false)
      
    } catch (error) {
      console.error("Error fetching withdrawals: ", error);
      setLoading(false)
    }
  };

  fetchInvestments();
}, []);  // Re-run the effect when accessToken changes

const{count=0,users:totalUsers=[]} = users;

if(loading) {
  return (
    <div className='flex flex-wrap h-screen flex-row items-center justify-center'>
      <div className="flex-item m-auto">
      <span className='block text-center'><LoadingSpinner/></span>
      <p>loading users data</p>
      </div>
    </div>
  )
}

const usersData = totalUsers.map(item => (
  <div key={item.id} className='card-container'>
    <div className="user-card">
      <div className="card-header">
        <div className="img-container inline-block">
          <img src={item?.profile} alt={item?.firstName} />
        </div>
      </div>
      <div className="card-body">
        <p className='text-[var(--white-color)] font-bold capitalize'>{item?.firstName} {item?.lastName}</p>
        <p>{item?.country}</p>
        <p>{item?.email}</p>
        <p>Joined Date : {new Date(item?.joinedDate).toUTCString()}</p>
        <p>KYC status : {item?.kyc === true ? <span className='verified'>verified</span> : <span className='unverified'>unverified</span>}</p>
      </div>
      <div className="card-footer">
        <div className="update-user-info inline-block">
        <div className="update-kyc">
          <Link className='cta-btn active' to={`/admin_tetfi/dashboard/update-kyc/${item?._id}`}>update kyc</Link>
        </div>
        <div className="update-wallet">
        <Link className='cta-btn' to={`/admin_tetfi/dashboard/update-wallets/${item?._id}`}>update wallet</Link>
        </div>
        <div className="update-wallet">
        <Link className='cta-btn' to={`/admin_tetfi/dashboard/update-transactions/${item?._id}`}>others</Link>
        </div>
        </div>
      </div>
    </div>
  </div>
))

  return (
    <section className='admin-dashboard'>
        <div className="flex justify-between items-start">
          <div className="flex-item">
          <h4>Hello Admin !</h4>
          </div>
          <div className="flex-item cursor-pointer" onClick={handleLogout}>
            <span className='flex items-center font-bold'>
            <LogoutIcon/> Logout
            </span>
          </div>
        </div>
        {/* Fetch users */}
        <p className="title text-xl">Total Number of Users : {count}</p>
        <div className='md:grid md:grid-cols-2 gap-8 md:items-center xl:grid-cols-3 md:mb-0'>
          {usersData}
        </div>
    </section>
  )
}

export default AdminDashboard