import React from "react";

function Whyus() {
  return (
    <section className="whyus">
      <h3
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      >why you have to choose us.</h3>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      className="why-choose-us">
        <p>
          Our mission is to improve lives by offering a secure platform, driven
          by innovative and effective solutions, for investing in emerging
          cryptocurrency markets. We are committed to providing our investors
          with cutting-edge tools and insights that allow them to navigate the
          complexities of the digital asset world with confidence. By leveraging
          advanced technology and data-driven strategies, we aim to enhance
          financial well-being and empower our users to make informed decisions.
          Our goal is not just to facilitate investments but to create a
          supportive environment where our clients can grow their wealth
          sustainably. We believe in the potential of cryptocurrencies to
          revolutionize finance, and we strive to make this opportunity
          accessible to everyone. Through our platform, we seek to offer
          personalized investment solutions that align with individual goals and
          risk tolerance. Ultimately, our mission is to provide financial
          freedom by helping our investors achieve their financial aspirations
          and secure a brighter, more prosperous future.
        </p>
      </div>
      <div className="mt-20 chooseus-steps flex items-center flex-col justify-center lg:grid lg:grid-cols-3 lg:place-items-center" id="whyus">
        <div 
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="item">
          <h4>01</h4>
          <p>Lucrative Returns</p>
        </div>
        <div
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="item">
        <h4>02</h4>
        <p>Fast Executions</p>
        </div>
        <div 
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="item">
        <h4>03</h4>
        <p>Investor Oriented</p>
        </div>
      </div>
    </section>
  );
}

export default Whyus;
