import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
import LoadingSpinner from "../shared/LoadingSpinner";

function Login() {
  const navigate = useNavigate()
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });


  // Utils functions
  // saved the access token to loacal storage
  const addToLocalStorage = (userProfile) => {
    localStorage.setItem("tetfi_user_profile", JSON.stringify(userProfile));
  };

  const storeToken = token => {
    localStorage.setItem("accessToken",token);
  }

  const handlePassword = () => {
    setPassword((prev) => !prev);
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: user.email,
      password: user.password,
    };
    const url = process.env.REACT_APP_LOGIN_URL;

    // console.log(url)
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {

        const response = await fetch(url, params);
        const resObj = await response.json();

        // if the response was false
        if (!response.ok) {
          throw new Error(resObj.message);
          // setLoading(false);
        }
        // console.log(resObj?.data);
        const token = resObj?.data?.accessToken;
        storeToken(token);
        // addToLocalStorage(resObj?.data?.accessToken);
        // Login the user after 3 seconds
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 3000);

      // GET THE USER PROFILE
      const profileUrl = process.env.REACT_APP_USER_PROFILE;
      const userProfile = await fetch(profileUrl,{
        method:"GET",
        headers:{
          "Content-Type": "application/json",
          "authorization":`Bearer ${token} `
        } 
      });

      const responseBody =  await userProfile.json();
      if (!userProfile.ok) {
        throw new Error(responseBody.message);
        // setLoading(false);
      }
      console.log(responseBody?.data)
      addToLocalStorage(responseBody?.data);
      toast.success("Login successful");
      setLoading(false);
      navigate("/dashboard");

      
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <section className="auth-section">
      <div className="form-container">
        {/* React hot Toast */}
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="form-header">
          <Link to="/" className="img-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={user.email}
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="form-field">
              <label htmlFor="password">Password</label>
              <input
                type={password ? "text" : "password"}
                name="password"
                id="password"
                value={user.password}
                placeholder="****"
                onChange={handleChange}
              />
              <span
                className={`eye-icon-container ${password ? "active" : ""}`}
                onClick={handlePassword}
              >
                <EyeIcon />
              </span>
            </div>

            <div className="form-field flex items-center justify-between">
              {/* <span>Do not have an account ?</span> */}
              <label className="remember-me" htmlFor="remember">
                 <input type="checkbox" id="remember" name="remember"/>
                 remember me
              </label>
              <Link className="text-[#2B6CB0]" to="/forgot-password">
                forgot password
              </Link>
            </div>

            <div className="form-field">
              <button disabled={loading}>
                <span>{loading ? <LoadingSpinner /> : "login"}</span>
              </button>
            </div>

            {/* OR */}

            <div className="form-field flex items-center justify-center">
              <span>Do not have an account ?</span>
              <Link className="text-[#2B6CB0]" to="/email-verification">
                create account
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;
