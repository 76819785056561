import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "./icons/DashboardIcon";
import LogoutIcon from "./icons/LogoutIcon";
import NotificationIcon from "./icons/NotificationIcon";
import WalletIcon from "./icons/WalletIcon";
import MyWalletIcon from "./icons/MyWalletIcon";
import InvestIcon from "./icons/InvestIcon";
import ReferralIcon from "./icons/ReferralIcon";
import ProfileIcon from "./icons/ProfileIcon";
import TermsIcon from "./icons/TermsIcon";
import HistroyIcon from "./icons/HistroyIcon";
import InvestmentsIcon from "./icons/InvestmentsIcon";
import WithdrawalIcon from "./icons/WithdrawalIcon";

function SideMenu({lastName,firstName,profile,handleLogout}) {
  // const navigate =  useNavigate();

//   useEffect(()=>{
//   const token  = localStorage.getItem("accessToken");
//   setAccessToken(token)
//   },
// [])


  return (
    <section className="side-menu">
       {/* <div className="border border-red-900 mb-8">
       <Link to="/dashboard" className="logo-container">
              <img src={`/assets/tetfi.png`} alt="logo" />
       </Link>
       </div> */}
       <div className="flex-item ml-4 mb-8 cursor-pointer gap-2">
            <div className="profile-img-container mb-2">
              <img src={profile} alt="" />
            </div>
          <h5 className='text-[#fff]'>{lastName} {firstName}</h5>
          </div>
      <nav className="nav-container">
        <ul className="list-menu">
          <li>
            <Link to="/dashboard" className="flex gap-4">
            <span className="inline-block align-middle">
              <DashboardIcon/>
            </span>
            dashboard
            </Link>
          </li>
          <li>
            <Link to="/dashboard/notifications" className="flex gap-4">
            <span className="inline-block align-middle">
              <NotificationIcon/>
            </span>
            notifications
            </Link>
          </li>
          <li>
            <Link to="/dashboard/fund_wallet" className="flex gap-4">
            <span className="inline-block align-middle">
              <WalletIcon/>
            </span>
            fund wallet
            </Link>
          </li>
          <li>
            <Link to="/dashboard/my_wallet" className="flex gap-4">
            <span className="inline-block align-middle">
              <MyWalletIcon/>
            </span>
            my wallet</Link>
          </li>
          <li>
            <Link to="/dashboard/invest" className="flex gap-4" >
            <span className="inline-block align-middle">
              <InvestIcon/>
            </span>
            invest</Link>
          </li>
          <li>
            <Link to="/dashboard/investments" className="flex gap-4">
            <span className="inline-block align-middle">
              <InvestmentsIcon/>
            </span>
            all investments</Link>
          </li>
          <li>
            <Link to="/dashboard/withdraw" className="flex gap-4">
            <span className="inline-block align-middle">
              <WithdrawalIcon/>
            </span>
            withdraw fund</Link>
          </li>
          <li>
            <Link to="/dashboard/withdrawal_history" className="flex gap-4">
            <span className="inline-block align-middle">
              <HistroyIcon/>
            </span>
            withdrawal history</Link>
          </li>
          <li>
            <Link to="/dashboard/ref" className="flex gap-4">
            <span className="inline-block align-middle">
              <ReferralIcon/>
            </span>
            referrals</Link>
          </li>
          <li>
            <Link to="/dashboard/profile" className="flex gap-4">
            <span className="inline-block align-middle">
              <ProfileIcon/>
            </span>
            profile</Link>
          </li>
          <li>
            <Link to="/terms_conditions" className="flex gap-4">
            <span className="inline-block align-middle">
              <TermsIcon/>
            </span>
            terms and privacy</Link>
          </li>
          <li>
            <Link to="/login" className="flex gap-4" onClick={handleLogout}>
            <span className="align-middle inline-block">
              <LogoutIcon/>
            </span>
            logout
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
}

export default SideMenu;
