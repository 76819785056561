import React from 'react'
import { Link } from 'react-router-dom'

function Support({marginVal}) {
  return (
    <div className={`message-box ${marginVal || "mb-20"}`}>
    <div className="message-contents flex flex-wrap items-center flex-col lg:flex-row">
      <div className="flex-item basis-[20px] lg:basis-[140px]">
        <div className="img-container">
          <img src={`/assets/chat-support.png`} alt="chat support" />
        </div>
      </div>
      <div className="flex-item basis-[48%] ml-auto mb-12 text-center">
        <h6 className='text-[#2B6CB0] font-bold'>We’re here to assist you!</h6>
        <p>You can ask a question, file a support ticket, manage requests, or report an issue. Our support team will respond to you via email.</p>
      </div>
      <div className="flex-item basis-[22%] lg:ml-auto">
        <div className="btn-container">
          <Link className='cta-btn chat-support' to="/contactus">chat support</Link>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Support