import React from "react";
import DashboardHome from "./DashboardHome";
import CheckIcon from "../icons/CheckIcon";
import CloseIcon from "../icons/CloseIcon";
import { Link } from "react-router-dom";
import Support from "./Support";
import InvestmentPackages from "./InvestmentPackages";

const InvestChildren = () => {
  // console.log(packages)

  return (
    <div className="invest-container mt-20">
      <h4 className="title uppercase">choose an investment plan</h4>
      <div className="invest-intro mb-8">
        <p>
          Welcome to the Invest section of Tetfi! We provide a secure and
          efficient platform for investing in top cryptocurrencies. With Tetfi,
          you can manage your investments in BTC, ETH, and USDT with ease,
          helping you build and grow your digital asset portfolio
        </p>
        <p>
          We offer a diverse range of investment plans for you to choose from.
          Select the option below that best suits your needs.
        </p>
      </div>
      <div className="why-invest md:flex items-center">
        <div className="flex-item basis-[48%]">
          <h4 className="title">why invest with tetfi</h4>
          <ul>
            <li>
              {" "}
              <strong>Simple and Secure:</strong> Tetfi offers a straightforward
              and secure way to invest in popular cryptocurrencies like Bitcoin,
              Ethereum, and Tether.{" "}
            </li>
            <li>
              {" "}
              <strong>Dedicated Wallets:</strong> Each investment option comes
              with a dedicated wallet, ensuring your assets are securely stored
              and easily accessible.
            </li>
            <li>
              {" "}
              <strong>Real time monitoring:</strong> Stay updated with live data
              and insights, helping you make informed decisions on your BTC,
              ETH, and USDT holdings.
            </li>
          </ul>
        </div>
        <div className="flex-item basis-[48%]">
          <div className="img-container">
            <img src={`/assets/investor-hand.png`} alt="investor hand" />
          </div>
        </div>
      </div>
      
      {/* Invest Packges */}
      <InvestmentPackages/>
      
      <div className="support-team">
        <Support marginVal="0"/>
      </div>
    </div>
  );
};
function Invest() {
  return <DashboardHome children={<InvestChildren />} />;
}

export default Invest;
