import React, { useState } from "react";
import NotificationIcon from "./icons/NotificationIcon";
import MenuBarIcon from "./icons/MenuBarIcon";
import { Link } from "react-router-dom";
import LogoutIcon from "./icons/LogoutIcon";
import ProfileIcon from "./icons/ProfileIcon";
import UpdateProfileIcon from "../icons/UpdateProfileIcon";

function DashboardHeader({
  firstName,
  lastName,
  profile,
  handleDisplaySideMenu,
  handleLogout,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  // Handle dropdown
  const handleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <section className="dashboard-header">
      <div className="flex-wrapper flex items-center gap-4 lg:gap-8">
        <div className="flex-item flex items-center gap-8 mr-auto">
          <div
            className="icon-container cursor-pointer"
            onClick={handleDisplaySideMenu}
          >
            <span className="inline-block">
              <MenuBarIcon />
            </span>
          </div>
          <Link to="/dashboard" className="logo-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="flex-item flex items-end cursor-pointer gap-2" onClick={handleDropdown}>
          <div className="profile-img-container">
            <img src={profile} alt="" />
          </div>
          <h5 className="hidden md:block">
            {lastName} {firstName}
          </h5>
          {showDropdown ? (
            <div className="dropdown absolute top-[115%]  right-4">
              <Link to="/dashboard/profile" className="flex gap-4">
                <span className="inline-block align-middle">
                  <ProfileIcon />
                </span>
                view profile
              </Link>
              <Link to="/dashboard/update-profile" className="flex gap-4">
                <span className="inline-block align-middle">
                  <UpdateProfileIcon />
                </span>
                update profile image
              </Link>
              <Link to="/login" className="flex gap-4" onClick={handleLogout}>
                <span className="align-middle inline-block">
                  <LogoutIcon />
                </span>
                logout
              </Link>
            </div>
          ) : null}
        </div>

        <div className="flex-item">
          <Link className="notification-icon p-0" to="/dashboard/notifications">
            <NotificationIcon />
            {/* <span className='notification-count'>1</span> */}
          </Link>
        </div>
      </div>
    </section>
  );
}

export default DashboardHeader;
