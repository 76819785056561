import React, { useEffect, useState } from "react";
import DashboardHome from "./DashboardHome";
import { Toaster, toast } from "react-hot-toast";
import LockIcon from "./icons/LockIcon";
import LoadingSpinner from "../shared/LoadingSpinner";
import EyeIcon from "../icons/EyeIcon";

function ProfileChildren() {
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [user, setUser] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const[accessToken,setAccessToken] = useState("")

  // Get the stored user id from the local storage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("tetfi_user_profile"));
    const token = localStorage.getItem("accessToken")
    setUserData(data);
    setAccessToken(token)
  }, []);
  const { firstName, lastName, country, email, phoneNumber, joinedDate } =
    userData;

  /**Paswword Update Script */

  // Handle Password and confirm passswords
  const handlePassword = () => {
    setPassword((prev) => !prev);
  };

  const handleNewPassword = () => {
    setNewPassword((prev) => !prev);
  };

  const handleConfirmPassword = () => {
    setConfirmPassword((prev) => !prev);
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      currentPassword: user.currentPassword,
      newPassword: user.newPassword,
      confirmPassword: user.confirmPassword,
    };
    const url = `${process.env.REACT_APP_UPDATE_PASSWORD}`;
    // console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authorization":`Bearer ${accessToken}`
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);
      setUser(prev => {
        return {
        ...prev,currentPassword:"",newPassword:"",confirmPassword:""
        }
      })
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
//   console.log(accessToken)
  return (
    <div className="mt-20 profile-container">
      <h4>personal information</h4>
      <p className="mb-8">
        The personal information you provided on Tetfi's Cryptocurrency
        Investments website during account registration.
      </p>
      <div className="table-container mb-16">
        <div className="table-header mb-8">
          <p className="uppercase title">Investor Profile Information</p>
        </div>
        <table className="profile-table table-element">
          <tbody className="table-body">
            <tr>
              <th>first Name</th>
              <td>{firstName}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
            <tr>
              <th>last name</th>
              <td>{lastName}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
            <tr>
              <th>email</th>
              <td>{email}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
            <tr>
              <th>country</th>
              <td>{country}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
            <tr>
              <th>joined date</th>
              {/* <td>{joinedDate}</td> */}
              <td>{new Date(joinedDate).toUTCString()}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
            <tr>
              <th>phone number</th>
              <td>{phoneNumber}</td>
              <td className="lock-icon">
                <LockIcon />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="password-change">
        <p className="title uppercase mb-8">Update Account Password</p>
        <div className="form-container">
          {/* React hot Toast */}
          <Toaster position="top-center" reverseOrder={false}></Toaster>
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label htmlFor="currentPassword">current password</label>
              <input
                type={password ? "text" : "password"}
                placeholder="Current Password"
                id="currentPassword"
                name="currentPassword"
                onChange={handleChange}
                value={user.currentPassword}
              />
              <span
                className={`eye-icon-container ${password ? "active" : ""}`}
                onClick={handlePassword}
              >
                <EyeIcon />
              </span>
            </div>
            <div className="form-field">
              <label htmlFor="newPassword">new password</label>
              <input
                type={newPassword ? "text" : "password"}
                placeholder="New Password"
                id="newPassword"
                name='newPassword' onChange={handleChange}
                value={user.newPassword}
              />
              <span
                className={`eye-icon-container ${newPassword ? "active" : ""}`}
                onClick={handleNewPassword}
              >
                <EyeIcon />
              </span>
            </div>
            <div className="form-field">
              <label htmlFor="confirm-password">confirm password</label>
              <input
                type={confirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                id="confirmPassword"
                name='confirmPassword' onChange={handleChange}
                value={user.confirmPassword}
              />
              <span
                className={`eye-icon-container ${confirmPassword ? "active" : ""}`}
                onClick={handleConfirmPassword}
              >
                <EyeIcon />
              </span>
            </div>
            <div className="form-field">
              <button  disabled={loading}>
                {loading ? <LoadingSpinner /> : "update password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function DashboardProfile() {
  return <DashboardHome children={<ProfileChildren />} />;
}

export default DashboardProfile;
