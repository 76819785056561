import React from 'react'
import Accordion from './Accordion'

function Faq() {
  return (
    <div className='faq-div' id='faq'>
        <h4 
          className='title'
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500">Referral FAQ (frequently ask questions)</h4>
        <Accordion/>
    </div>
  )
}

export default Faq;