import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";

import {  useSearchParams } from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";
import Header from "../Header/Header";

function ContactUs() {
  const [serchParams] = useSearchParams();
  const email = serchParams.get("email")
  const [loading, setLoading] = useState(false);
   
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  });



  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      message:user.message
    };
    const url = `${process.env.REACT_APP_CONTACTUS_URL}`;
    console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);

    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <>
    <Header/>
    <section className="contactus-section">
      <div className="form-container">
        {/* React hot Toast */}
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="form-header">
          <h3>contact us.</h3>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="double">
              <div className="form-field">
                <label htmlFor="firstName">first name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Jeo"
                  value={user.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-field">
                <label htmlFor="lastName">last name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Dick"
                  value={user.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={user.email}
                placeholder="you@gmail.com"
                onChange={handleChange}
              />
            </div>
           
            <div className="form-field">
              <label htmlFor="message">how can we help you ?</label>
              <textarea name="message" id="message" cols="24" rows="4" onChange={handleChange} value={user.message}></textarea>
            </div>

            <div className="form-field">
              <button disabled={loading}>
                <span>{loading ? <LoadingSpinner /> : "send message"}</span>
              </button>
            </div>
            {/* OR */}
          </form>
        </div>
      </div>
    </section>
    </>
  );
}

export default ContactUs;
