import React, { useEffect, useState } from "react";
import DashboardFooter from "./DashboardFooter";
import DashboardHeader from "./DashboardHeader";
import SideMenu from "./SideMenu";
import DashboardMainBody from "./DashboardMainBody";
import { useNavigate } from "react-router-dom";

function DashboardHome({ children }) {
  // const[accessToken,setAccessToken] = useState("");
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  // Handle Logout
  function handleLogout() {
    localStorage.removeItem("accessToken");
    setAccessToken(null);
  }

  //Handle Side Menu Display
  const handleDisplaySideMenu = () => {
    setShowSideMenu((prev) => !prev);
  };
  
  // Get the stored user id from the local storage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("tetfi_user_profile"));
    const token = localStorage.getItem("accessToken");
    setUserData(data);
    setAccessToken(token);
  }, []);



  // check if the token is null and redirect to login
  if (!accessToken) {
    navigate("/login");
  }

  // console.log(accessToken);
  // const { firstName,profile } = userData;
  return (
    <div className="dashboard-container">
      <DashboardHeader
        {...userData}
        handleDisplaySideMenu={handleDisplaySideMenu}
        handleLogout={handleLogout}
      />
      <div className="grid-2">
        <div className="grid-item aside-container">
          <aside className={showSideMenu ? "aside active" : "aside"}>
            <SideMenu {...userData} handleLogout={handleLogout} />
          </aside>
        </div>
        <div className="grid-item">
          <section className="main-section">
            {children || <DashboardMainBody {...userData} />}
          </section>
        </div>
      </div>
      <DashboardFooter />
      <div
        className={showSideMenu ? "close-side-menu active" : "close-side-menu"}
        onClick={handleDisplaySideMenu}
      ></div>
    </div>
  );
}

export default DashboardHome;
