import React from 'react'

function PageNotFound() {
  return (
    <section>
    <h4>OOPs...!!!</h4>
    <p>Page Not Found</p>
    </section>
  )
}

export default PageNotFound