import React from "react";
import Header from "../Header/Header";
import HeroSection from "./HeroSection";
import Aboutus from "./Aboutus";
import Packages from "./Packages";
import UpwardCounter from "./UpwardCounter";
import Faq from "./Faq";
import TradingWidget from "./TradingView";
import TradingViewTimelineWidget from "./TradingNews";
import Testimonials from "./Testimonials";
import Launching from "./Launching";
import Whyus from "./Whyus";
import HowToFundAccount from "./HowToFundAccount";
import Footer from "../Footer/Footer";

function Home() {
  return(
    <div>
     <Header />
     <HeroSection/>
     <UpwardCounter/>
     <Aboutus/>
     <Packages/>
     <HowToFundAccount/>
      <section>
        <TradingWidget/>
      </section>
     <Whyus/>
     <Faq/>
     <TradingViewTimelineWidget/>
     <Testimonials/>
     <Launching/>
     {/* <ContactUs/> */}
     {/* <GoogleTranslate/> */}
     <Footer/>
  </div> 
  );
} 


export default Home;
