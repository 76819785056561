import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import PageNotFound from "./NotFound/PageNotFound";
import Aboutus from "./Home/Aboutus";
// import Header from "./Header/Header";
import EmailVerification from "./Auth/EmailVerification";
// import Footer from "./Footer/Footer";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import DashboardHome from "./Dashboard/DashboardHome";
import ForgotPassword from "./Auth/ForgotPassword";
import ForgotPasswordOTP from "./Auth/ForgotPasswordOTP";
import EmailVerificationOTP from "./Auth/EmailVerificationOTP";
import ContactUs from "./Footer/ContactUs";
import TermsAndConditions from "./Footer/TermsAndConditions";
import PrivacyPolicy from "./Footer/PrivacyPolicy";
import ScrollToTop from "./Home/ScrollToTop";
import DashboardProfile from "./Dashboard/DashboardProfile";
import DashboardReferral from "./Dashboard/DashboardReferral";
import DashboardWithdrawalHistory from "./Dashboard/DashboardWithdrawalHistory";
import DashboardInvestments from "./Dashboard/DashboardInvestments";
import FundWallet from "./Dashboard/FundWallet";
import MyWallet from "./Dashboard/MyWallet";
import Invest from "./Dashboard/Invest";
import Notifications from "./Dashboard/Notifications";
// import Day2 from "./pract/Day2";
import Withdrawal from "./Dashboard/Withdrawal";
import SelectedPlan from "./Dashboard/SelectedPlan";
import SelectedWallet from "./Dashboard/SelectedWallet";
import AdminLogin from "./Admin/AdminLogin";
import AdminDashboard from "./Admin/AdminDashboard";
import ProtectedRoute from "./Admin/ProtectedRoute";
import UpdateKyc from "./Admin/UpdateKyc";
import UpdateUserWallet from "./Admin/UpdateUserWallet";
import UpdateTransactionStatus from "./Admin/UpdateTransactionStatus";
import UpdateProfile from "./Dashboard/UpdateProfile";


// Pract Route



// const router = createBrowserRouter(
//   createRoutesFromElements(
//     <> 
//       <Route path="/" element={<Home/>} />
//       <Route path="/about" element={<Aboutus/>} />
//       <Route path="*" element={<PageNotFound/>} />
//     </>
//   ),
// );

function Main(props) {
  return (
    <>
      <Router>
        {/* <Header/> */}
        <ScrollToTop/>
        <Routes>
          <Route  path="/" element={<Home/>}/>
          <Route  path="/about" element={<Aboutus/>}/>
          <Route  path="/contactus" element={<ContactUs/>}/>
          <Route  path="/terms_conditions" element={<TermsAndConditions/>}/>
          <Route  path="/privacy_policy" element={<PrivacyPolicy/>}/>
          {/* <Route  path="/pract" element={<Day2/>}/> */}

          {/* AUTH ROUTES */}
          <Route  path="/register" element={<Register/>}/>
          <Route  path="/login" element={<Login/>}/>
          <Route  path="/email-verification" element={<EmailVerification/>}/>
          <Route  path="/verify-auth-otp" element={<EmailVerificationOTP/>}/>
          <Route  path="/forgot-password" element={<ForgotPassword/>}/>
          <Route  path="/forgot-password-otp" element={<ForgotPasswordOTP/>}/>
          
          {/* Admin ROUTES */}
          <Route  path="/admin_tetfi/login" element={<AdminLogin/>}/>
          <Route  path="/admin_tetfi/dashboard" element={<ProtectedRoute><AdminDashboard/></ProtectedRoute>}/>
          <Route  path="/admin_tetfi/dashboard/update-kyc/:userId" element={<UpdateKyc/>}/>
          <Route  path="/admin_tetfi/dashboard/update-wallets/:userId" element={<UpdateUserWallet/>}/>
          <Route  path="/admin_tetfi/dashboard/update-transactions/:userId" element={<UpdateTransactionStatus/>}/>


          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<DashboardHome/>}/>
          <Route path="/dashboard/profile" element={<DashboardProfile/>}/>
          <Route path="/dashboard/update-profile" element={<UpdateProfile/>}/>
          <Route path="/dashboard/ref" element={<DashboardReferral/>}/>
          <Route path="/dashboard/withdrawal_history" element={<DashboardWithdrawalHistory/>}/>
          <Route path="/dashboard/investments" element={<DashboardInvestments/>}/>
          <Route path="/dashboard/fund_wallet" element={<FundWallet/>}/>
          <Route path="/dashboard/my_wallet" element={<MyWallet/>}/>
          <Route path="/dashboard/invest" element={<Invest/>}/>
          <Route path="/dashboard/invest/plan/:id" element={<SelectedPlan/>}/>
          <Route path="/dashboard/selected-wallet/:id" element={<SelectedWallet/>}/>
          <Route path="/dashboard/withdraw" element={<Withdrawal/>}/>
          <Route path="/dashboard/notifications" element={<Notifications/>}/>

          <Route  path="*" element={<PageNotFound/>}/>
        </Routes>
        {/* <Footer/> */}
      </Router>
    </>
  );
}

export default Main;
