import React from 'react'

function Launching() {
  return (
    <section className='launching-soon'>
        <div 
        data-aos="flip-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="launching-container">
            <div className="lg:flex items-center">
                <div className="flex-item basis-[48%]">
                    <h4>
                        we are launching soon !
                    </h4>
                </div>
                <div className="flex-item basis-[48%]">
                    <p className='mb-8'>Our Android and iOS mobile apps are currently in development and will soon be available on the Play Store and App Store</p>
                    <div className="flex-container">
                        <div className="flex-item">
                            <div className="img-container">
                                <img src={`/assets/appstorebadge-icon.svg`} alt="apple" />
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className="img-container">
                                <img src={`/assets/googleplaybadge-icon.svg`} alt="google" />
                            </div>
                        </div>
                        <div className="flex-item"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Launching