import React from 'react'
import Accordion from './Accordion'

function Faq() {
  return (
    <section className='faq-section' id='faq'>
        <h3 
          data-aos="fade-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500">FAQ.</h3>
        <Accordion/>
    </section>
  )
}

export default Faq