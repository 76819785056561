import React, { useEffect, useState } from "react";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { Toaster, toast } from "react-hot-toast";
import "./modal.css";
import CloseIcon from "../../icons/CloseIcon";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { useNavigate } from "react-router-dom";

function FundWalletModal({ modal, toggleModal, amount, assetData }) {
 const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(modal);
  const [inputVal, setInputVal] = useState(null);
  const [loading, setLoading] = useState(false);
  const[accessToken,setAccessToken] = useState("")

  // Assets Data
  const { network, walletAddress } = assetData;


   // Handle file Upload
   const fileUpload = async () => {
    const data = new FormData();
    data.append("file", inputVal);
    data.append("upload_preset", "images_preset");
    try {
      const cloudName = process.env.REACT_APP_CLOUNDNAME;
      let resourceType = "image";
      let apiKey = `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`;

      const res = await axios.post(apiKey, data);
      const { secure_url } = res.data;
      return secure_url;
    } catch (err) {
      toast.error(err.message)
      // console.log(err);
    }
  };

  // Handle Change
  const handleChange = (e) => {
    setInputVal((prev) => e.target.files[0]);
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    setAccessToken(token)
  }, []);

  useEffect(() => {
    setModalVisible(modal);
  }, [modal]);

  // Convert file to base64
  /*
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      //  When onload
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      // check if theres errror uploading
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  }
  */


  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const imgSrc = await fileUpload();
    const data = {
      transactionType:"Deposit",
      amount: amount,
      network:network,
      imgFile:imgSrc
    };


    const url = process.env.REACT_APP_DEPOSIT_TRANSACTION;

    // console.log(url)
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }

      toast.success(resObj.message);
      setLoading(false);
      //Go to Idashboard the user after 3 seconds
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);

    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      {modal && (
        <div className="modal-container">
          <div className="modal-overlay" onClick={toggleModal}></div>
          <div
            className={`modal-content ${isModalVisible ? "show-modal" : ""}`}
          >
            <div className="modal-header mb-2">
              <h5 className="title">Deposit fund</h5>
              <p className="title">
                Click "Copy wallet" or scan the QR code to get the wallet
                address and upload your transaction screenshot to complete the
                deposit.
              </p>
            </div>
            <div className="modal-body">
              <p className="capitalize mb-2 flex flex-col lg:flex-row justify-between">
                <span>
                  {" "}
                  Network : <span className="font-bold">{network}</span>{" "}
                </span>
                <span>
                  <span className="text-[var(--primary-color)] font-bold">
                    Amount in USDT:
                  </span>{" "}
                  {amount}{" "}
                </span>
              </p>
              <div className="qr-code text-center mb-4">
                <QRCodeSVG className="img" value={walletAddress} />
              </div>
              <div className="wallet-address mb-2">
                <p className="text-[var(--dark-color)] font-bold">
                  Wallet Address:
                </p>
                <span>{walletAddress}</span>
              </div>
              <div className="form-container">
                <form onSubmit={handleSubmit}>
                  {/* React hot Toast */}
                  <Toaster position="top-center" reverseOrder={false}></Toaster>
                  <div className="form-field">
                    <label className="title" htmlFor="upload-receipt">
                      Please upload a screenshot of the transaction for
                      verification. <span className="text-[var(--red-color)]"> Note: We only accept image files like .jpg,.Png e.t.c</span>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="upload-receipt"
                      onChange={handleChange}
                    />
                  </div>
                  <button className="submit ml-auto" disabled={loading}>
                    {loading ? <LoadingSpinner /> : "submit"}
                  </button>
                </form>
              </div>
            </div>
            <div className="close-modal" onClick={toggleModal}>
              <div className="img-container">
                {/* <img src={`/assets/close-icon.svg`} alt="close modal" /> */}
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FundWalletModal;
