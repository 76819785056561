import React, { useState } from 'react';

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="accordion-item">
      <button className="accordion-header" onClick={onClick}>
        {title}
      </button>
      {isOpen && (
        <div className="accordion-content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const data = [
    {
      title: 'What is Tetfi?',
      content: 'Tetfi is a cryptocurrency investment platform that offers a variety of digital asset investment options. We provide the tools and support you need to make informed decisions in the crypto market.',
    },
    {
      title: 'How do I start investing with Tetfi?',
      content: 'Sign up on our platform, complete the verification process, and fund your account with your preferred cryptocurrency. Once set up, you can explore and invest in a range of digital assets.',
    },
    {
      title: 'Is my cryptocurrency safe with Tetfi?',
      content: ' Absolutely. Tetfi uses cutting-edge blockchain security measures, including multi-signature wallets and two-factor authentication, to ensure your investments are secure. We also work with regulated custodians to protect your digital assets.',
    },
    {
      title: 'What cryptocurrencies can I invest in on Tetfi?',
      content: 'We offer a diverse selection of cryptocurrencies, including Bitcoin, Ethereum, and a range of altcoins, as well as emerging tokens and DeFi projects.',
    },
    {
      title: 'Can I withdraw my funds anytime?',
      content: 'Yes, you can withdraw your funds at any time, subject to network transaction fees. We strive to process withdrawals quickly and securely.',
    },
  ];

  return (
    <div 
    data-aos="fade-down"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="500"
    className="accordion">
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={activeIndex === index}
          onClick={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
