import React, { useState } from 'react';

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="accordion-item">
      <button className="accordion-header" onClick={onClick}>
        {title}
      </button>
      {isOpen && (
        <div className="accordion-content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const data = [
    {
      title: 'How do I share my referral link?',
      content: ' You can share your link via social media, email, or directly messaging your contacts.',
    },
    {
      title: 'Is there a limit to how many people I can refer?',
      content: 'No, there is no limit. You can refer as many people as you like.',
    },
    {
      title: 'When do I receive my referral rewards?',
      content: 'Rewards are credited to your account within 48 hours once your referral completes their first investment.',
    },
  ];

  return (
    <div 
    data-aos="fade-down"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="500"
    className="accordion">
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={activeIndex === index}
          onClick={() => toggleAccordion(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
