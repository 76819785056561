import React from 'react'
import { Link } from 'react-router-dom'

function AccountDetails({wallets}) {
  return (
    <div className="account-info">
        <div className="wallet-container mt-12">
            <div className="grid-container grid lg:grid-cols-2 gap-8">
                <div className="grid-item border-item">
                    <div className="flex items-center gap-4">
                        <div className="flex-item">
                            <div className="icon-container">
                                <img src={`/assets/depositwallet.svg`} alt="interest wallet" />
                            </div>
                        </div>
                        <div className="flex-item">
                            <h5 className="title mb-4">Deposit wallet</h5>
                            <div className="balance-details gap-8 flex">
                                <div className="flex-item">
                                <h6>Deposit</h6>
                                 <p>$ {wallets?.depositWallet}</p>
                                </div>
                                <div className="flex-item">
                                    <h6>Total Invest</h6>
                                    <p>$ {wallets?.totalInvestment}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item border-item">
                     <div className="flex items-center gap-4">
                        <div className="flex-item">
                            <div className="icon-container">
                                <img src={`/assets/interestwallet.svg`} alt="interest wallet" />
                            </div>
                        </div>
                        <div className="flex-item">
                            <h5 className="title mb-4">Interest wallet</h5>
                            <div className="balance-details gap-8 flex">
                                <div className="flex-item">
                                <h6>Interest</h6>
                                 <p>$ {wallets?.interestWallet}</p>
                                </div>
                                <div className="flex-item">
                                    <h6>Total Withdrawal</h6>
                                    <p>$ {wallets?.totalWithdrawals}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="btn-contianers">
                        <Link className='cta-btn active' to="/dashboard/fund_wallet">fund account</Link>
                        <Link className='cta-btn' to="/dashboard/withdraw">withdraw</Link>
                        {/* <Link className='cta-btn' to="/dashboard/invest">invest now</Link> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccountDetails