import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer 
    data-aos="fade-down"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="500"
    className="footer-container index-footer">
      <section>
      <nav>
        <ul>
          <li>
            <Link to="#">About Tetfi</Link>
            {/* <!-- No closing </li> for dropdown menu  here --> */}
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/about">about us</Link>
              </li>
              <li>
                <Link to="/contactus">Contact us</Link>
              </li>
              <li>
                <Link to="/privacy_policy">privacy Policy</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">Products</Link>
            {/* <!-- No closing </li> for dropdown menu  here --> */}
            <ul>
            <li>
                <Link to="/#coming-soon">Desktop Applicaton</Link>
              </li>
              <li>
                <Link to="/terms_conditions">terms and conditions</Link>
              </li>
              <li>
                <Link to="/#whyus">why us</Link>
              </li>
              <li>
                <Link to="/login">Buy Gift Card</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">Contact Us</Link>
            {/* <!-- No closing </li> for dropdown menu  here --> */}
            <ul>
            <li>
                <Link to="/contactus">Contact us</Link>
              </li>
              <li>
                <Link to="/email-verification">Buy Crypto</Link>
              </li>
              <li>
                <Link to="#" className="location-text">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#a7a7a7"
                  >
                    <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                  </svg>
                  Chiswick Park, London, W4 5YA, United Kingdom
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="#">Support</Link>
            {/* <!-- No closing </li> for dropdown menu  here --> */}
            <ul>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>
              <li>
                <Link to="/contactus">Support Center</Link>
              </li>
              <li>
                <Link to="mailto:info.tetfi@gmail.com">Give Us Feedback</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <div className="copy-right text-center">
        <small> &copy; Copyright Tefti {year}</small>
      </div>
      </section>
    </footer>
  );
}

export default Footer;
