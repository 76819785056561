import React, { useState } from 'react'
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from 'react-router-dom';
import LoadingSpinner from '../shared/LoadingSpinner';

function UpdateUserWallet() {
    const {userId} = useParams();
    const [formData,setFormData] = useState({
    walletType:"",
    amount:""
    });
    const[loading,setLoading] = useState(false)

    // Handle Chnage
    const handleChange = e => {
    setFormData(prev => ({...prev,[e.target.name]:e.target.value}))
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
    
        // Simple input validation
        if (!formData.walletType || !formData.amount) {
            toast.error("Please enter or select a value");
            return;
          }
    
        setLoading(true)
        const data ={
        wallets:{
        [formData.walletType]:formData.amount
        }
        }
        console.log(data)
        const url = `${process.env.REACT_APP_UPDATE_WALLET}/${userId}`;
        // console.log(url)
        const params = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(data)
        };
        try {
          const res = await fetch(url, params);
          const resObj = await res.json();
          if(!res.ok){
            throw new Error(resObj.message)
          }
          toast.success(resObj.message)
          setLoading(false)
          
        } catch (error) {
          toast.error("Error fetching: ", error);
          setLoading(false)
        }
    
        }
  return (
    <section className='admin-dashboard'>
        <h5 className="title font-bold uppercase">update user wallets</h5>
        <div className="form-container">
                {/* React hot Toast */}
                 <Toaster position="top-center" reverseOrder={false}></Toaster>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        {/* <input type="text" placeholder='Enter true or false' value={inputVal} name='kyc' onChange={handleChange}/> */}
                        <label htmlFor="walletType">Select Wallet to update</label>
                        <select name="walletType" id="walletType" onChange={handleChange}>
                            <option value="">Select Wallet</option>
                            <option value="BTC">BTC</option>
                            <option value="ETH">ETH</option>
                            <option value="USDT">USDT</option>
                            <option value="depositWallet">deposit wallet</option>
                            <option value="interestWallet">interest wallet</option>
                            <option value="totalInvestments">total investments</option>
                            <option value="totalWithdrawals">total withdrawals</option>
                        </select>
                    </div>
                    <div className="form-field">
                        <label htmlFor="amount">Amount</label>
                        <input type="text" name='amount' value={formData.amount} id='amount' onChange={handleChange} />
                    </div>
                    <div className="form-field">
                        <button className='cta-btn active'>
                            {
                            loading ? <LoadingSpinner/> : "Submit"
                            }
                        </button>
                    </div>
                </form>
                <div className="goback">
                <Link className='font-bold' to="/admin_tetfi/dashboard">
                go back to home
                </Link>
                </div>
            </div>
    </section>
  )
}

export default UpdateUserWallet