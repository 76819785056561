import React, { useEffect, useState } from 'react'
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import LoadingSpinner from "../shared/LoadingSpinner";
// import useProfile from '../hooks/useProfile';
import ProfileUpload from './icons/ProfileUpload';


function UpdateProfile() {
    const navigate = useNavigate();
    const[inputVal,setInputVal] =  useState(null);
    const[accessToken,setAccessToken] = useState("");
    const[loading,setLoading] = useState(false);
    // const[userData] = useProfile();
    const[updatedProfile,setUpdatedProfile] = useState(null);

    // const {profile:existingProfile} = userData;
  
    
    useEffect(() => {
      const token = localStorage.getItem("accessToken")
      setAccessToken(token)
    }, []);
  
     // Handle file Upload
     const fileUpload = async () => {
      const data = new FormData();
      data.append("file", inputVal);
      data.append("upload_preset", "images_preset");
      try {
        const cloudName = process.env.REACT_APP_CLOUNDNAME;
        let resourceType = "image";
        let apiKey = `https://api.cloudinary.com/v1_1/${cloudName}/${resourceType}/upload`;
  
        const res = await axios.post(apiKey, data);
        const { secure_url } = res.data;
        return secure_url;
      } catch (err) {
        toast.error(err.message)
        // console.log(err);
      }
    };
  
  
    // Handle Change
    const handleChange = event => {
      setInputVal(event.target.files[0]);
    }
  
    const handleUserProfile = async(e) => {
     e.preventDefault();
     setLoading(true);
      const url = process.env.REACT_APP_UPDATE_PROFILE;
      const profileImg = await fileUpload();
       const data = {
        newProfile:profileImg
       }
      // console.log(url)
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };
      try {
        const response = await fetch(url, params);
        const resObj = await response.json();
  
        // if the response was false
        if (!response.ok) {
          throw new Error(resObj.message);
          // setLoading(false);
        }
  
        toast.success(resObj.message);
        setUpdatedProfile(resObj?.data?.updatedUser?.profile);
        setLoading(false);
        //Go to Idashboard the user after 3 seconds
        localStorage.removeItem("accessToken")
        setTimeout(() => {
          navigate("/login");
        }, 5000);
  
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    }
    // console.log(updatedProfile)
  return (
    <section className='profile-update'>
   <div className="profile-wrapper">
   <div className="form-container">
        {/* React hot Toast */}
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <form onSubmit={handleUserProfile}>
        <div className="form-field">
              <input className='hidden' id='profile' type="file" onChange={handleChange}/>
              <label htmlFor="profile" className='cursor-pointer'>
                {
                 updatedProfile ? (
                    <div className="img-container">
                    <img src={updatedProfile} alt={updatedProfile} />
                    </div>
                 ):
                 <div className="img-container profile-upload">
                 <span className='inline-block'><ProfileUpload/></span>
                 </div>
                } 
              </label>
        </div>
        <button className="cta-btn">
          {
          loading ? <LoadingSpinner/> : " Update profile"
          }
        </button>
        </form>
    </div>
   </div>
    </section>
  )
}

export default UpdateProfile