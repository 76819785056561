import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import DashboardHome from "./DashboardHome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PACKAGES as packages } from "../shared/Data";
import useProfile from "../hooks/useProfile";
import Support from "./Support";
import LoadingSpinner from "../shared/LoadingSpinner";

const SelectedPlanChildren = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedPackage, setSelectedPackage] = useState({});
  const [formData, setFormData] = useState({
    amount: "",
    investmentWallet: "depositWallet",
  });
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");

  const [userDetails] = useProfile();

  useEffect(() => {
    const selectedItem = packages.find((item) => item.id === parseInt(id));
    setSelectedPackage(selectedItem);
  }, [id]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  const { type, maxDep, minDep, monthProfit, monthlyBonus } = selectedPackage;
  const { firstName, lastName } = userDetails;

  // Handle change
  const handleChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      selectedPlan: type,
      amount: formData.amount,
      investmentWallet: formData.investmentWallet,
    };

    console.log(data);

    const url = `${process.env.REACT_APP_INVEST}`;

    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);

      // Login the user after 3 seconds
      setTimeout(() => {
        navigate("/dashboard");
      }, 5000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="selected-plan mt-20">
      <div className="header-container mb-4">
        <h4>
          Selected Plan :{" "}
          <span
            className="selected-package"
            style={{
              background: `${type === "Bronze" ? "var(--light-blue)" : type === "Silver" ? "var(--teal-color)" : type === "Gold" ? "var(--secondary-color)" : ""}`,
            }}
          >
            {type}
          </span>
        </h4>
      </div>
      <div className="plan-details mb-8">
        <h5>
          You have selected{" "}
          <span className="text-[var(--red-color)] font-bold text-xl inline-block px-2 mb-2">
            {type}
          </span>{" "}
          Plan for your investment.
        </h5>
        <p>
          Invest a minimum of ${minDep} and a maximum of ${maxDep} to earn{" "}
          {monthProfit} weekly interest{" "}
        </p>
      </div>
      <div className="investment-details mb-20">
        <div className="form-container">
          {/* React hot Toast */}
          <Toaster position="top-center" reverseOrder={false}></Toaster>
          <div className="form-body mb-8">
            <form onSubmit={handleSubmit}>
              <div className="md:flex gap-[4%]">
                <div className="flex-item basis-[48%]">
                  <div className="form-header flex items-center justify-between mb-4">
                    <p className="title capitalize">enter amount to invest</p>
                    <div className="btn-container">
                      <Link to="/dashboard/fund_wallet" className="cta-btn">
                        fund wallet
                      </Link>
                    </div>
                  </div>
                  <div className="form-field">
                    <input
                      type="number"
                      name="amount"
                      onChange={handleChange}
                      value={formData.amount}
                      placeholder={`Enter between $${minDep} to $${maxDep}`}
                      min={200}
                    />
                    {/* <span className="currency-name absolute uppercase right-10 top-4 text-[var(--grey-color)] font-bold">usd</span> */}
                    <span className="italic text-[var(--red-color)] text-sm">
                      Note: Please enter numbers only. Special characters such
                      as "$" or "," should be omitted from the amount.
                    </span>
                  </div>
                  <p className="title my-8">Select Wallet for Investment</p>
                  <div className="double">
                    <div className="form-field">
                      <input
                        type="radio"
                        id="interestWallet"
                        name="investmentWallet"
                        value="interestWallet"
                        checked={formData.investmentWallet === "interestWallet"}
                        onChange={handleChange}
                      />
                      <label htmlFor="interestWallet">interest wallet</label>
                    </div>
                    <div className="form-field">
                      <input
                        type="radio"
                        id="depositWallet"
                        name="investmentWallet"
                        checked={formData.investmentWallet === "depositWallet"}
                        onChange={handleChange}
                        value="depositWallet"
                      />
                      <label htmlFor="depositWallet">deposit wallet</label>
                    </div>
                  </div>
                </div>
                <div className="flex-item basis-[38%] ml-auto">
                  <h5 className="title text-center">Your investment Summary</h5>
                  <div className="grid-container grid grid-cols-2 gap-2 p-2 mt-4">
                    <div className="grid-item">
                      <h5>Investor</h5>
                      <p className="capitalize">
                        {lastName} {firstName}
                      </p>
                    </div>

                    <div className="grid-item">
                      <h5>Selected Plan</h5>
                      <p className="uppercase">{type}</p>
                    </div>

                    <div className="grid-item">
                      <h5>Profit</h5>
                      <p>{monthProfit} weekly interest</p>
                    </div>
                    <div className="grid-item">
                      <h5>Min Amount</h5>
                      <p>${minDep}</p>
                    </div>

                    <div className="grid-item">
                      <h5>Max Amount</h5>
                      <p>${maxDep}</p>
                    </div>
                    <div className="grid-item">
                      <h5>Weekly RIO</h5>
                      <p>{monthProfit}</p>
                    </div>

                    <div className="grid-item">
                      <h5>Monthly Bonus</h5>
                      <p>${monthlyBonus}</p>
                    </div>
                    <div className="grid-item">
                      <h5>Terms starts at</h5>
                      <p>Today - {new Date().toUTCString()}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="total-amount">
                    <p className="my-4 lg:text-center">
                      Total Amount : <span>${formData.amount}</span>
                    </p>
                  </div>
                  <div className="form-field">
                    <div className="btn-container">
                      <button
                        className="cta-btn submit"
                        disabled={loading}
                      >
                        {loading ? <LoadingSpinner /> : "Proceed to Deposit"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <p className="italic">
            By clicking{" "}
            <span className="text-[var(--primary-color)] font-bold">
              'Proceed to Deposit,'
            </span>{" "}
            you confirm that you have read, understood, and agree to abide by
            the Terms and Conditions of Tetfi. This includes our policies on
            privacy, data usage, and investment risks. Please ensure that you
            have carefully reviewed all relevant documents and disclosures. Your
            continued use of our platform signifies your consent to these terms
            and your acknowledgment of the potential risks associated with
            cryptocurrency investments.
          </p>
        </div>
      </div>
      <div className="support-team">
        <Support marginVal="0" />
      </div>
    </div>
  );
};

function SelectedPlan() {
  return <DashboardHome children={<SelectedPlanChildren />} />;
}

export default SelectedPlan;
