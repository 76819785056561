import React from 'react'

function Aboutus() {
  return (
    <section className='mt-20'>
       <div 
        className="lg:flex items-center gap-8">
        <div 
        data-aos="fade-up"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="flex-item mb-12 lg:basis-[48%]">
        <h3
        data-aos="fade-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        >About Tetfi.</h3>
        <p>At Tetfi, we are at the forefront of the cryptocurrency revolution, providing innovative investment solutions for the digital age. Established in 2023, Tetfi has grown into a trusted platform, offering a wide array of cryptocurrency investment opportunities.</p>
        <p>Our mission is to democratize access to cryptocurrency investments, ensuring that anyone, regardless of their financial background, can participate in this transformative market. We leverage advanced blockchain technology and deep market insights to deliver a platform that is not only user-friendly but also secure and effective in driving financial growth.</p>
        </div>
        <div 
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        className="flex-item lg:basis-[48%]">
            <div className="img-container">
                <img src={`/assets/aboutus-2.png`} alt="about us" />
            </div>
        </div>
       </div>
    </section>
  )
}

export default Aboutus