import React from 'react'

function HowToFundAccount() {
  return (
    <section className='fund-wallet'>
        <h3
        data-aos="fade-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        >how to invest.</h3>
        <div className="flex flex-wrap  flex-col lg:flex-row lg:items-center gap-[4%]">
            <div 
            data-aos="fade-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="flex-item basis-[48%]">
                <div className="img-container inline-block">
                    <img src={`assets/funding-steps-2.png`} alt="funding process" />
                </div>
            </div>
            <div 
            data-aos="fade-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="flex-item basis-[48%] lg:basis-[22%] lg:ml-auto">
            <ul>
      <li>create account</li>
      <li>login to dashboard</li>
      <li>select investment plan</li>
      <li>fund wallet</li>
      {/* <li>choose any of the newtwork for funding (BTC, ETH, USDT)</li> */}
      <li>make payment done</li>
    </ul>
            </div>
        </div>
    </section>
  )
}

export default HowToFundAccount