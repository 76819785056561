import React from 'react'
import DashboardHome from './DashboardHome'
import { WALLETDATA } from '../shared/Data';
import { Link } from 'react-router-dom';


const WithdrawalChildren = () => {
  const assetsDataRow = WALLETDATA.map((item,index) => (
    <tr className="trade-data-row" key={item.id}>
      <td className="table-data">
        <div className="cryptocurrency-pairs currency-symbol">
          <div className="image-container first-symbol">
            <img src={item.icon} alt={item.network} />
          </div>
          <span className="currency-symbol">{item.network}</span>
        </div>
      </td>
      <td className="table-data"> {item.avalaible} </td>
      <td className="table-data link deposit" id="two">
        <Link to={`/dashboard/selected-wallet/${index}`}  className="cta-btn active">
          {" "}
          Withdraw
        </Link>{" "}
      </td>
      {/* <td className="table-data link withdraw"> Withdraw </td>
                  <td className="table-data link transfer"> Transfer </td> */}
    </tr>
));

return (
<div className="withdraw-container mt-20">
    <h4 className='title'>Withdraw fund</h4>
    <p className='mb-8'>You can securely transfer your cryptocurrency assets from your Tetfi account to your external wallet. Our streamlined process ensures fast and safe withdrawals of your BTC, ETH, or USDT funds. Please follow the instructions carefully to complete your request.</p>
    
    {/* use referral section style */}
    <div className="referral">
      <p className="title mb-4">To initiate a withdrawal, please complete the following steps:</p>
    <ol>
          <li> <strong>Select the Wallet : </strong> Choose the cryptocurrency wallet (BTC, ETH, or USDT) from which you want to withdraw funds.</li>
          <li> <strong>Enter the Amount : </strong> Specify the amount you wish to withdraw. Ensure that you have sufficient balance to cover the withdrawal and any associated network fees.</li>
          <li> <strong>Provide Destination Address : </strong> Input the external wallet address where you want to receive your funds. Double-check the address for accuracy, as cryptocurrency transactions cannot be reversed.</li>
          <li> <strong>Confirm Withdrawal Request : </strong> Review your request and confirm the details. Once confirmed, the withdrawal will be processed, and you’ll receive a notification once the transaction is complete.</li>
      </ol>
    </div>

    <div className="withdraw-assets fundwallet-container">
    <div className="assets-table-container">
          <div className="align-left trade-chart">
            <table className="tabular-data">
              <thead>
                <tr className="trade-data-row">
                  <th className="table-head">Asset</th>
                  <th className="table-head">Available</th>
                  <th className="table-head"></th>
                  {/* <th className="table-head"></th>
                    <th className="table-head"></th> */}
                </tr>
              </thead>
             <tbody>
              {assetsDataRow}
             </tbody>
            </table>
          </div>
        </div>
    </div>
</div>
)
}


function Withdrawal() {
  return (
    <DashboardHome children={<WithdrawalChildren/>} />
  )
}

export default Withdrawal