

// PACKAGES DATA
export const  PACKAGES = [
{
 id:0,
 type:"Bronze",
 minDep:"200",
 maxDep:"5000",
 monthProfit:"60%",
 dailyProfit:"2%",
 duration:"2 Weeks",
 ReferralBonus:"5%",
 monthlyBonus:"N/A"
},
{
 id:1,
 type:"Silver",
 minDep:"5000",
 maxDep:"10000",
 monthProfit:"75%",
 dailyProfit:"2.5%",
 duration:"3 Weeks",
 ReferralBonus:"5%",
 monthlyBonus:"N/A"
},
{
 id:2,
 type:"Gold",
 minDep:"10000",
 maxDep:"Unlimited",
 monthProfit:"90%",
 dailyProfit:"3%",
 duration:"1 Month",
 ReferralBonus:"5%",
 monthlyBonus:"3%"
},
]




// WALLET DATA'
export const WALLETDATA =  [
{
 id:0,
 network:"BTC",
 icon:`/assets/BTC.png`,
 avalaible:"0",
 locked:"0",
 total:"0",
 usdValue:"0.00",
 walletAddress:`bc1q02v702388lj6gnk73m22h8nx8ykzq4k5jmm45n`
},
{
 id:1,
 network:"ETH",
 icon:`/assets/ETH.png`,
 avalaible:"0",
 locked:"0",
 total:"0",
 usdValue:"0.00",
 walletAddress:`0x6835367D7f4F2B853216e8CB33dd2970ba279E04`
},
{
 id:2,
 network:"USDT",
 icon:`/assets/USDT.png`,
 avalaible:"0",
 locked:"0",
 total:"0",
 usdValue:"0.00",
 walletAddress:`TYi6PJP5fuPCMKzEg8gjBeyns88gVRKc99`
},
]