import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CryptoConverter = () => {
  useEffect(() => {
    // Dynamically load the script after the component mounts
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/gh/dejurin/crypto-converter-widget@1.5.2/dist/latest.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Crypto Converter Widget */}
      <crypto-converter-widget
        amount="1"
        shadow="true"
        symbol="true"
        live="true"
        fiat="united-states-dollar"
        crypto="bitcoin"
        font-family="inherit"
        background-color="#2b6cb0"
        decimal-places="2"
        border-radius="0.7rem"
      ></crypto-converter-widget>

      {/* Link to CurrencyRate.Today */}
      <Link
        href="https://currencyrate.today/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginTop: '10px', display: 'inline-block' }}
      >
        {/* CurrencyRate.Today */}
      </Link>
    </div>
  );
};

export default CryptoConverter;
