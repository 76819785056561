import React, { useEffect, useState } from 'react'
import { Toaster, toast } from "react-hot-toast";
import DashboardHome from './DashboardHome'
import { useNavigate, useParams } from 'react-router-dom';
import { WALLETDATA } from '../shared/Data';
import LoadingSpinner from '../shared/LoadingSpinner';



const SelectedWalletChildren =  () => {
const navigate = useNavigate()
const {id} = useParams();
const [selectedWallet,setSelectedWallet] = useState({});
const [withdrawalData,setWithdrawalData] = useState({
 walletAddress:"",
 amount:"",
 network:""
});
const[loading, setLoading] = useState(false);
const[accessToken,setAccessToken] = useState("")


 useEffect(()=>{
    const selectedItem = WALLETDATA.find(item => item.id === parseInt(id));
    setSelectedWallet(selectedItem)
    },[id])

  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    setAccessToken(token)
  }, []);


// Handle Change
const handleChange = event => {
setWithdrawalData(prev => ({...prev, [event.target.name]:event.target.value}));
}


// Handle Submit
const handleSubmit = async(event) => {
event.preventDefault();
setLoading(true);

const data = {
transactionType:"withdrawal",
walletAddress:withdrawalData.walletAddress,
amount:withdrawalData.amount,
network:network
};

const url = `${process.env.REACT_APP_WITHDRAWAL}`;

const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, params);
    const resObj = await response.json();

    // if the response was false
    if (!response.ok) {
      throw new Error(resObj.message);
      // setLoading(false);
    }
    // console.log(resObj?.data);
    toast.success(resObj?.message);
    setLoading(false);

    // Login the user after 3 seconds
    setTimeout(() => {
      navigate("/dashboard");
    }, 5000);
  } catch (err) {
    toast.error(err.message);
    setLoading(false);
  }
};

// console.log(selectedWallet)
const { network , available, icon} = selectedWallet;



    // Check if the available balance is Greater than the entered input

return(
<div className='mt-20'>
 <p className='text-[var(--dark-color)] text-xl'>You have selected <span className='text-[var(--primary-color)] font-bold text-xl'> {network} </span> network for withdrawal !</p>
 <p className='italic mb-4'>Please enter the Amount and Provide a destination address to complete your withdrawal</p>
 <div className="form-container">
     {/* React hot Toast */}
     <Toaster position="top-center" reverseOrder={false}></Toaster>
    <form onSubmit={handleSubmit}>
        <div className="form-header">
            {/* <h5 className="title">withdraw {network}</h5> */}
        </div>
        <div className="form-body">
            <div className="form-field">
                <label htmlFor="walletAddress">wallet address</label>
                <input type="text" name='walletAddress' id='walletAddress' onChange={handleChange} value={withdrawalData.walletAddress}  placeholder='Provide a wallet address'/>
            </div>
            <div className="form-field">
                <label htmlFor="network">network</label>
                <input type="text" name='network' id='network' value={network} readOnly={true}/>
                <span className="icon-container">
                    <img src={icon} alt={network}/>
                </span>
            </div>
            <div className="form-field">
                <label htmlFor="amount">Amount</label>
                <input type="number" name='amount' id='amount' value={withdrawalData.amount} onChange={handleChange}  placeholder='Provide amount'/>
            </div>
        
           <button className='cta-btn submit' disabled={loading}>{loading ? <LoadingSpinner/> : "confirm order"}</button>
            
        </div>
    </form>
 </div>
</div>
)
}









function SelectedWallet() {
  return (
   <DashboardHome children={<SelectedWalletChildren/>} />
  )
}







export default SelectedWallet