import React, { useState } from 'react';
import { Toaster, toast } from "react-hot-toast";
import LoadingSpinner from '../shared/LoadingSpinner';
import { Link, useNavigate } from 'react-router-dom';


function ForgotPassword() {

  const navigate =  useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });



  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: user.email,
    };
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const url = process.env.REACT_APP_RESET_PASSWORD;
      const response = await fetch(url, params);
      const resObj = await response.json();
      // setUserCredentials(prev => ({...prev,email:resObj?.email,authOTP:resObj?.authOTP}))

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
      }
      
      const  authCredentials = resObj?.data;
      console.log(authCredentials)
      if(authCredentials){
         // go to verify otp page after 3 seconds
        // to convert btoa(), use atob()
        toast.success(resObj?.message);
        setLoading(false);
         setTimeout(()=>{
          navigate(`/forgot-password-otp?authToken=${authCredentials.authToken}`);
         },3000)
      }

    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  }
  return (
    <section className='auth-section'>
      <div className="form-container">
         {/* React hot Toast */}
         <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="form-header">
          <Link to="/" className="img-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}> 
            <div className="form-field">
              <label htmlFor="email">Enter registered email to reset your password</label>
              <input type="email" name="email" id="email" value={user.email} onChange={handleChange} placeholder='Email' />
            </div>
            <div className="form-field">
              <button disabled={loading}>
                <span>{loading ? <LoadingSpinner/> : "reset password"}</span>
              </button>
            </div>
            {/* OR */}
            <div className='option-label'>
              <span>OR</span>
            </div>

            <div className="form-field">
              <Link className='auth-cta-btn' to="/login">login</Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword