import React from 'react';
import { PACKAGES as packages } from "../shared/Data";
import CheckIcon from '../icons/CheckIcon';
import CloseIcon from '../icons/CloseIcon';
import { Link } from 'react-router-dom';

function InvestmentPackages() {
    const packageItems = packages.map((item) => (
        <div
          className="card-container mb-8"
          key={item.id}
        >
          <figure className="card flex justify-center flex-col items-center">
            <div className="card-header text-center">
              <h4 className="mb-8">{item.type}</h4>
              <p className="mb-4">
                {" "}
                <span className="profit font-bold text-xl lg:text-2xl">{item.dailyProfit} </span>
                <sup className="text-sm">/ Daily</sup>
              </p>
            </div>
            <div className="card-body">
              <ul>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  minimum deposit : <span className="amount ml-2">{item.minDep}</span>
                </li>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  maximum deposit : <span className="amount ml-2">{item.maxDep}</span>
                </li>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  monthly profit : <span className="amount ml-2">{item.monthProfit}</span>
                </li>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  daily profit : <span className="amount ml-2">{item.dailyProfit}</span>
                </li>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  Duration : <span className="amount ml-2">{item.duration}</span>
                </li>
                <li>
                  <span className="check-icon">
                    <CheckIcon />
                  </span>
                  Referral Bonus : <span className="amount ml-2">{item.ReferralBonus}</span>
                </li>
                <li>
                  <span className="close-icon">
                    <CloseIcon />
                  </span>
                  monthly bonus : <span className="amount ml-2">{item.monthlyBonus}</span>
                </li>
              </ul>
            </div>
            <div className="card-footer">
              <div className="btn-container">
                <Link to={`/dashboard/invest/plan/${item.id}`} className="cta-btn">
                  select plan
                </Link>
              </div>
            </div>
          </figure>
        </div>
      ));

    return(
        <div className="select-package affordable-packages mb-8">
        <div className="cards card-group lg:grid lg:grid-cols-2 gap-8">
        {packageItems}
        </div>
      </div>
    )
}

export default InvestmentPackages