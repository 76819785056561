import React, { useState } from 'react'
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from 'react-router-dom';
import LoadingSpinner from '../shared/LoadingSpinner';



const UpdateDepositTransactions = () => {
 const [formData,setFormData] = useState({
    transactionId:"",
    confirmed:""
    });
    const[loading,setLoading] = useState(false)

    // Handle Chnage
    const handleChange = e => {
    setFormData(prev => ({...prev,[e.target.name]:e.target.value}))
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
    
        // Simple input validation
       if (!formData.transactionId || !formData.confirmed) {
            toast.error("Please enter the necessary details to validate deposit transaction");
            return;
          }
    
        setLoading(true)
        const data ={
        confirmed:formData.confirmed.toLocaleLowerCase()
        }
        console.log(data)
        const url = `${process.env.REACT_APP_UPDATE_DEPOSIT}/${formData.transactionId}`;
        // console.log(url)
        const params = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(data)
        };
        try {
          const res = await fetch(url, params);
          const resObj = await res.json();
          if(!res.ok){
            throw new Error(resObj.message)
          }
          toast.success(resObj.message)
          setLoading(false)
          
        } catch (error) {
          toast.error(error.message);
          setLoading(false)
        }
    
        }

return (
    <section className='admin-dashboard'>
    <h5 className="title font-bold uppercase">update user deposit transaction</h5>
    <div className="form-container">
            {/* React hot Toast */}
             <Toaster position="top-center" reverseOrder={false}></Toaster>
             <form onSubmit={handleSubmit}>
            <div className="form-field">
                <input type="text" placeholder='Transaction Id' value={formData.transactionId} name='transactionId' onChange={handleChange}/>
            </div>
              <div className="form-field">
                <input type="text" placeholder='Enter true or false to validate deposit' value={formData.confirmed} name='confirmed' onChange={handleChange}/>
            </div>
                <div className="form-field">
                    <button className='cta-btn active'>
                        {
                        loading ? <LoadingSpinner/> : "Save Changes"
                        }
                    </button>
                </div>
            </form>
        </div>
</section>
)
}
const UpdateWithrawalTransactions = () => {
    const [formData,setFormData] = useState({
     transactionId:"",
     confirmed:""
    });
    const[loading,setLoading] = useState(false)

    // Handle Chnage
    const handleChange = e => {
    setFormData(prev => ({...prev,[e.target.name]:e.target.value}))
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        // Simple input validation
        if (!formData.transactionId || !formData.confirmed) {
            toast.error("Please enter the necessary details to validate withdrawal transaction");
            return;
          }
    
        setLoading(true)
        const data ={
        confirmed:formData.confirmed.toLocaleLowerCase()
        }
        console.log(data)
        const url = `${process.env.REACT_APP_UPDATE_WITHDRAWAL}/${formData.transactionId}`;
        // console.log(url)
        const params = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body:JSON.stringify(data)
        };
        try {
          const res = await fetch(url, params);
          const resObj = await res.json();
          if(!res.ok){
            throw new Error(resObj.message)
          }
          toast.success(resObj.message)
          setLoading(false)
          
        } catch (error) {
          toast.error(error.message);
          setLoading(false)
        }
    
        }

     return (
    <section className='admin-dashboard'>
    <h5 className="title font-bold uppercase">update user Withdrawal transaction</h5>
    <div className="form-container">
            {/* React hot Toast */}
             <Toaster position="top-center" reverseOrder={false}></Toaster>
            <form onSubmit={handleSubmit}>
            <div className="form-field">
                <input type="text" placeholder='Transaction Id' value={formData.transactionId} name='transactionId' onChange={handleChange}/>
            </div>
              <div className="form-field">
                <input type="text" placeholder='Enter true or false to validate withdrawal' value={formData.confirmed} name='confirmed' onChange={handleChange}/>
            </div>
                <div className="form-field">
                    <button className='cta-btn active'>
                        {
                        loading ? <LoadingSpinner/> : "Save Changes"
                        }
                    </button>
                </div>
            </form>
            <div className="goback">
            <Link className='font-bold' to="/admin_tetfi/dashboard">
            go back to home
            </Link>
            </div>
        </div>
</section>
)
}

function UpdateTransactionStatus() {
    // const {userId} = useParams();
  return (
  <>
  <UpdateDepositTransactions/>
  <UpdateWithrawalTransactions/>
  </>
  )
}

export default UpdateTransactionStatus