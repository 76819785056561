import React, { useEffect, useState } from "react";
import "./modal.css";
import CloseIcon from "../../icons/CloseIcon";
import useCryptoAssets from "../../hooks/useCryptoAssets";
import FundWalletModal from "./FundWalletModal";

function DepositFundModal({ modal, toggleModal,onClick,modalTwo, assetData }) {

  const [isModalVisible,setModalVisible] = useState(modal);
  const[inputVal,setInputVal] = useState("");
  const[coin,setCoin] =  useState(0)

// fetch CyptoCurrency Values
const [{BTC,ETH,USDT,isLoading,serverError}] =  useCryptoAssets();
const { network } = assetData;

useEffect(() =>{
const assetType = network === "BTC" ? BTC : network === "ETH" ? ETH : network ==="USDT" ? USDT : ""
const convertToBTC = (inputVal/assetType);
setCoin(convertToBTC);
},[inputVal, BTC, ETH, USDT, network])

// console.log(coin)


// Handle Proceed to Confirm
// const handleProceed = () => {
 
// }

// Handlchange
const handleChange = (e) => {
 setInputVal(e.target.value);
}





  useEffect(()=>{
    setModalVisible(modal);
  },[modal])


//  if(isFinite(btc)){
//     // console.log(btc)
//  }

  return (
    <>
      {modal && (
        <div className="modal-container">
          <div className="modal-overlay" onClick={toggleModal}></div>
          <div className={`modal-content ${isModalVisible ? "show-modal" : ""}`}>
            <div className="modal-header">
            <h5 className="title">Fund Wallet</h5>
            <p>Selected Network : {network}</p>
            </div>
            <div className="modal-body">
              <div className="form-container">
                <form>
                    <div className="form-field">
                        <label htmlFor="amount">amount : <span className="font-bold text-[var(dark-color)]">{inputVal}</span></label>
                        <input type="number" onChange={handleChange} name="amount" id="amount" value={inputVal} min={200} required={true} placeholder="Enter $200 and above" />
                    </div>
                    <div className="display-asset-conversion mb-8 text-center">
                        <p>{inputVal} <span className="font-bold text-[var(--primary-color)]"> USDT </span>  = {isLoading ? "fetching asset value.." : serverError ? serverError : (isFinite(coin) ? coin : "") } <span className="font-bold text-[var(--primary-color)]">{network}</span></p>
                    </div>
                    <div className="form-field">
                        <div className="btn-container">
                            <button className="cta-btn active" onClick={onClick}>Proceed to Confirm</button>
                        </div>
                    </div>
                </form>
              </div>
            </div>
            <div className="close-modal" onClick={toggleModal}>
              <div className="img-container">
                {/* <img src={`/assets/close-icon.svg`} alt="close modal" /> */}
                <CloseIcon/>
               </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Goes here */}
      <FundWalletModal modal={modalTwo} toggleModal={onClick} amount={inputVal} assetData={assetData}/>
    </>
  );
}

export default DepositFundModal;
