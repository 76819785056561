import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
// background:url(../../public/assets/template-hero-bg-0.png) no-repeat center/cover #f8f8f8;

// load the image Data
const imageData = [
  `/assets/template-hero-bg-0.png`,
  `/assets/template-hero-bg-1.png`,
  `/assets/template-hero-bg-2.png`,
  `/assets/template-hero-bg-3.png`
];



function HeroSection() {
  const[position,setPosition] = useState(0);

  // During Mount using SIde Effects
  useEffect(()=>{
    const interval = setInterval(()=>{
     if(position === imageData.length - 1) setPosition(0);
     else{
      setPosition(prev => prev + 1)
     }
    },5000);
    return () => clearInterval(interval);
  },[position]);

  return (
    <section className="hero-section" style={{background:`url(${imageData[position]}) no-repeat center/cover #f8f8f8`}}>
      <div className="hero-section-wrapper">
        <h2 className="">
          Your Gateway to <br />
          <strong className="strong-text"> Smart Crypto </strong>
          <br/>
          Currency Investments.
        </h2>
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            "Investing in Cryptocurrency",
            1000, // wait 1s before replacing with the next message
            "Exploring New Investment Opportunities",
            1000,
            "Maximizing Your Crypto Portfolio",
            1000,
            "Innovative Investment Strategies",
            1000,
          ]}
          wrapper="h4"
          speed={50}
          style={{ fontSize: "1.6em", display: "inline-block" }}
          repeat={Infinity}
        />

        {/* cta btns */}
        <div className="btn-container">
          <Link className="cta-btn" to="/email-verification">
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
