
import React, { useEffect, useState } from 'react'

function useProfile() {
const [userProfile,setUserProfile] =  useState("");

  // Get the stored user id from the local storage
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("tetfi_user_profile"));
    setUserProfile(data);
  }, []);

  return [userProfile,setUserProfile];
}

export default useProfile