import React from 'react'
import DashboardHome from './DashboardHome';
import useProfile from '../hooks/useProfile';
import AccountDetails from './AccountDetails';


const WalletChildren = () => {
  const [user] =  useProfile();
  const {lastName, wallets= {}}  = user;
return (
<div className='my-wallet-container mt-20'>
 <h4>Hi {lastName}!</h4>
 <p>Welcome to the "My Wallet" page on Tetfi. Here, you can view and manage your cryptocurrency holdings with ease. Whether you’re monitoring your balances, making transactions, or reviewing your wallet history, this page is designed to provide you with all the tools and information you need to effectively manage your digital assets.</p>
 
 <div className="wallet-details">
 <AccountDetails wallets={wallets}/>
 </div>

</div>
)
}

function MyWallet() {
  return (
    < DashboardHome children={<WalletChildren/>}/>
  )
}

export default MyWallet