import React, { useEffect, useState } from "react";
import useProfile from "../hooks/useProfile";
import { QRCodeCanvas } from "qrcode.react";
import DashboardHome from "./DashboardHome";
import Modal from "./Modal/Modal";
import FundWalletModal from "./Modal/FundWalletModal";
import DepositFundModal from "./Modal/DepositFundModal";
import { WALLETDATA } from "../shared/Data";


const FundWalletChildren = () => {
  const [userProfile] =  useProfile();
  const [modal, setModal] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const[activeIndex,setActiveIndex] = useState(null);
  const[selectedItem,setSelectedItem] = useState({});
  

  // Handle Modal Proceed to Confirm
  const handleProceed = () => {
    setModal(false);
    setModalTwo((prev) => !prev);
  };


  // Hanle active index 
 const handleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index );
 }

  // Handle Modal Logic
  const toggleModal = () => {
    setModal((prev) => !prev);
  };

useEffect(()=> {
// selected Item 
const selectedAsset = WALLETDATA.find(item => item.id === parseInt(activeIndex));
 setSelectedItem(selectedAsset || {})
},[activeIndex])
//  const {network,}

// Get the user
const {wallets = {}} = userProfile;

  const assetsDataRow = WALLETDATA.map((item,index) => (
      <tr className="trade-data-row" key={item.id}>
        <td className="table-data">
          <div className="cryptocurrency-pairs currency-symbol">
            <div className="image-container first-symbol">
              <img src={item.icon} alt={item.network} />
            </div>
            <span className="currency-symbol">{item.network}</span>
          </div>
        </td>
        <td className="table-data"> {item.network === "BTC" ? wallets?.BTC : item.network === "ETH" ? wallets?.ETH : item.network === "USDT" ? wallets?.USDT :""  } </td>
        <td className="table-data"> {item.network === "BTC" ? wallets?.BTC : item.network === "ETH" ? wallets?.ETH : item.network === "USDT" ? wallets?.USDT :""  } </td>
        <td className="table-data"> {item.network === "BTC" ? wallets?.BTC : item.network === "ETH" ? wallets?.ETH : item.network === "USDT" ? wallets?.USDT :""  } </td>
        {/* <td className="table-data"> {item.network === "BTC" ? wallets?.BTC :""} </td>
        <td className="table-data"> {item.network === "BTC" ? wallets?.BTC :""} </td> */}
        <td className="table-data link deposit" id="two">
          <span onClick={()=>{
            toggleModal()
            handleActiveIndex(index)
          }} className="cta-btn active">
            {" "}
            Deposit
          </span>{" "}
        </td>
        {/* <td className="table-data link withdraw"> Withdraw </td>
                    <td className="table-data link transfer"> Transfer </td> */}
      </tr>
  ));

  return (
    <div className="fundwallet-container mt-20">
      <div className="intro mb-8">
        <h4>fund your account</h4>
        <p>
          At Tetfi, we strive to make the investment process as seamless as
          possible. Funding your investment account is the first step toward
          taking advantage of our platform's opportunities. Follow the steps
          below to ensure your account is properly funded and ready for
          investment.
        </p>
      </div>
      <div className="fund-account mb-8">
        <h5 className="title">Fund Your Account with Cryptocurrency</h5>
        <p className="text-sm mb-8">
          Please Provide the necessary details below to generate your Bitcoin
          wallet for account funding. Should you encounter any issues with the
          funding process, please do not hesitate to contact us for assistance.
        </p>
        <p className="title uppercase">
          select any of the assets to fund account
        </p>
        <div className="assets-table-container">
          <div className="align-left trade-chart">
            <table className="tabular-data">
              <thead>
                <tr className="trade-data-row">
                  <th className="table-head">Asset</th>
                  <th className="table-head">Available</th>
                  <th className="table-head">Locked</th>
                  <th className="table-head">Total</th>
                  <th className="table-head"></th>
                  {/* <th className="table-head"></th>
                    <th className="table-head"></th> */}
                </tr>
              </thead>
             <tbody>
              {assetsDataRow}
             </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal Goes Here */}
      <DepositFundModal
        modal={modal}
        onClick={handleProceed}
        modalTwo={modalTwo}
        toggleModal={toggleModal}
        assetData={selectedItem}
        />
      
    </div>
  );
};

function FundWallet() {
  return <DashboardHome children={<FundWalletChildren />} />;
}

export default FundWallet;
