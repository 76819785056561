import React, { useEffect, useState } from 'react';
import { AiOutlineCopy } from "react-icons/ai";
import CopyToClipboard from 'react-copy-to-clipboard';
import DashboardHome from './DashboardHome';
import useProfile from '../hooks/useProfile';
import Faq from './Faq';



const ReferralChidren =  () => {
  const[userProfile] = useProfile();
  // console.log(userProfile)
  const { lastName, email, referralCount} = userProfile;
  const[copy, setCopy] = useState(false);
  const[inputVal, setInputVal] = useState('');
  
  // console.log(userProfile);

  useEffect(()=>{
   setInputVal(`www.tetfi.ng/email-verification?referralEmail=${email}`)
  },[email])


  // set the input value to a link

return (
    <div className="mt-20 referral">
      <div className="ref-intro mb-8">
      <p
       data-aos="fade-up"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="500"
       className='mb-4' 
      >
      <h3>Hello, {lastName}!</h3>
      Welcome to the <span className='text-[#2B6CB0] text-xl uppercase inline-block font-bold'>Tetfi</span> Referral Program! We believe in rewarding our community for spreading the word about our platform. By inviting your friends and family to join Tetfi, you not only help them discover the benefits of investing in emerging cryptocurrency markets but also earn rewards for yourself. It's a win-win!
      </p>
      <p>
      You can refer family, friends, acquaintances, or customers to create an investment account with us by sharing your referral or affiliate link. Just copy the referral link below and send it to them to register.
      </p>
      </div>
      <div 
      data-aos="fade-left"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
       className="how-it-works md:flex mb-8">
        <div className="flex-item">
        <h4 className="title">How it Works</h4>
        <p>Our referral program is simple and straightforward:</p>
        <ol>
          <li> <strong>Share Your Unique Referral Link:</strong> When you sign up for Tetfi, you’ll receive a unique referral link that you can share with others.</li>
          <li> <strong>Invite Friends to Join:</strong> Send your referral link to friends, family, or anyone interested in investing with Tetfi.</li>
          <li> <strong>Earn Rewards:</strong> Once your referrals sign up using your link and make their first investment, you'll receive a reward credited to your Tetfi account.</li>
        </ol>
        </div>
        <div className="flex-item" id='ref-link'>
          <div className="img-container">
            <img src={`/assets/referral.png`} alt="referral" />
          </div>
        </div>
      </div>
      <div 
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      className="ref-link mb-8">
      <h4 className='title'>Referral Link</h4>
      <p className='mb-4'>Copy and Share the link below with your friends to get your referal income.</p>
      <div className="ref-link-inner-container flex flex-wrap gap-[4%] mb-8 items-center">
      <input type="text" readOnly className='text-sm basis-[48%]  lg:basis-[68%]' name='copy' value={inputVal} />
       <CopyToClipboard text={inputVal} onCopy={() => setCopy(true)}>
       <span className={`copy-icon cursor-pointer inline-block flex align-middle items-center ${copy ? "copied" : ""}`}>
        <AiOutlineCopy/>
        {copy ? "Copied" : "Copy link"}
      </span>
       </CopyToClipboard>
      </div>
      </div>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      className="ref-benefits mb-8">
        <h4 className="title">benefits</h4>
        <ul>
          <li> <strong>Earn Extra Income:</strong> Each successful referral adds a bonus to your account, boosting your investment potential.</li>
          <li> <strong>Unlimited Referrals:</strong> There’s no limit on how many people you can refer— the more you invite, the more you earn.</li>
          <li> <strong>Help Others Grow:</strong> Introduce your network to a reliable platform where they can safely invest in cryptocurrency and work towards financial freedom.</li>
        </ul>
      </div>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      className="ref-get-started mb-8">
        <h4 className="title">get started with Tefti referral</h4>
        <ol>
          <li> <strong>Login Into Your Tefti:</strong> Head over to the referral section in your dashboard.</li>
          <li> <strong>Copy Your Referrral Link:</strong> Easily accessible in your account, copy and share your unique link.</li>
          <li> <strong>Track Your Referrals:</strong> Keep an eye on your dashboard to see how many people have signed up and your accumulated rewards.</li>
        </ol>
      </div>
      <div 
      data-aos="fade-up"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      className="ref-terms mb-8">
        <h4 className="title">terms and conditions</h4>
        <ul>
          <li>The referral program is open to all registered Tetfi users.</li>
          <li>To qualify for rewards, referred users must sign up using your unique referral link and complete their first investment. </li>
          <li>Rewards will be credited to your account within 48 hours of the qualifying investment.</li>
          <li>Tetfi reserves the right to modify or terminate the referral program at any time without prior notice.</li>
          <li>Self-referrals or fraudulent activities to exploit the referral program will result in disqualification and potential account suspension.</li>
        </ul>
      </div>
      <div className="ref-history mb-8">
      <h4 className='title'>Referral History</h4>
      <p className='title'>Total Number of referrals  : <span className='text-xl font-bold text-[#2B6CB0]'>{referralCount ? referralCount : "0"}</span></p>
      <p>Referral Bonus : 5% of your monthly earnings</p>
      </div>

      <div className="faq">
        <Faq/>
      </div>
    </div>
)
}

function DashboardReferral() {
  return (
    <DashboardHome children={<ReferralChidren/>}/>
  )
}

export default DashboardReferral