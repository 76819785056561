import React from 'react'
import Slider from 'react-slick'
function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section className='testimonials'>
      <h3
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      >what our users are saying.</h3>
      <div className="cards card-group">
       <Slider {...settings}>
       <div className="card-container">
          <figure className="card">
            <div className="card-header mb-8">
              <div className="flex items-center gap-4">
                <div className="flex-item">
                  <div className="img-container">
                    <img src={`/assets/testi-1.svg`} alt="stanley" />
                  </div>
                </div>
                <div className="flex-item">
                  {/* <p className="username">Stanley,Sofia,Elsa, and Sydney</p> */}
                  <p className="username text-white text-xl">Stanley</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>Every aspect of my investment strategies on Tetfi is flawless, thanks to the platform's precise analytics. Tetfi has truly elevated my investment experience.</p>
            </div>
          </figure>
        </div>
       <div className="card-container">
          <figure className="card">
            <div className="card-header mb-8">
              <div className="flex items-center gap-4">
                <div className="flex-item">
                  <div className="img-container">
                    <img src={`/assets/testi-2.svg`} alt="stanley" />
                  </div>
                </div>
                <div className="flex-item">
                  {/* <p className="username">Stanley,Sofia,Elsa, and Sydney</p> */}
                  <p className="username text-white text-xl">Sofia</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>The platform’s real-time insights have been invaluable, helping me make swift and profitable investment choices on Tetfi.</p>
            </div>
          </figure>
        </div>
       <div className="card-container">
          <figure className="card">
            <div className="card-header mb-8">
              <div className="flex items-center gap-4">
                <div className="flex-item">
                  <div className="img-container">
                    <img src={`/assets/testi-3.svg`} alt="stanley" />
                  </div>
                </div>
                <div className="flex-item">
                  {/* <p className="username">Stanley,Sofia,Elsa, and Sydney</p> */}
                  <p className="username text-white text-xl">Elsa</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>Tetfi has revolutionized my cryptocurrency investments with its intuitive interface and real-time analytics, making my portfolio growth steady and informed</p>
            </div>
          </figure>
        </div>
       <div className="card-container">
          <figure className="card">
            <div className="card-header mb-8">
              <div className="flex items-center gap-4">
                <div className="flex-item">
                  <div className="img-container">
                    <img src={`/assets/testi-4.svg`} alt="stanley" />
                  </div>
                </div>
                <div className="flex-item">
                  {/* <p className="username">Stanley,Sofia,Elsa, and Sydney</p> */}
                  <p className="username text-white text-xl">Sydney</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>Tetfi’s automation tools save me time by tracking market moves and suggesting trades, allowing me to focus on long-term investment strategies..</p>
            </div>
          </figure>
        </div>
       <div className="card-container">
          <figure className="card">
            <div className="card-header mb-8">
              <div className="flex items-center gap-4">
                <div className="flex-item">
                  <div className="img-container">
                    <img src={`/assets/testi-2.svg`} alt="stanley" />
                  </div>
                </div>
                <div className="flex-item">
                  {/* <p className="username">Stanley,Sofia,Elsa, and Sydney</p> */}
                  <p className="username text-white text-xl">Sofia</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p>Tetfi made diving into cryptocurrency easy, with clear resources and excellent support that helped me quickly grow my investments..</p>
            </div>
          </figure>
        </div>
       </Slider>
      </div>
    </section>
  )
}

export default Testimonials