import React from 'react'
import { Link } from 'react-router-dom'

function DashboardFooter() {
  return (
    <section className='dashboard-footer'>
        <footer className='dashboard-footer-container'>
            <div className="list-item-container">
                <ul className="flex flex-row flex-wrap justify-center">
                    <li>
                        <Link to="/dashboard">dashboard</Link>
                    </li>
                    <li>
                        <Link to="/privacy_policy">privacy</Link>
                    </li>
                    <li>
                        <Link to="/terms_conditions">terms & conditiosn</Link>
                    </li>
                </ul>
            </div>
        </footer>
    </section>
  )
}

export default DashboardFooter