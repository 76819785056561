import React from "react";
import { Link } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";

function Menu() {
  return (
    <div className="menu-container">
      <ul className="list-menu">
        <div className="close-icon">
          <span>
            <RiCloseLine />
          </span>
        </div>
        <li>
          <Link to="/">home</Link>
        </li>
        <li>
          <Link to="/about">about us</Link>
        </li>
        <li>
          <Link to="/#investment-plans">services</Link>
        </li>
        <li>
          <Link to="/#faq">FAQ</Link>
        </li>
        <li>
          <Link to="/contactus">contact us</Link>
        </li>
        <li>
          <Link to="/login">sign in</Link>
        </li>
        <li>
          <div className="btn-container">
          <Link className="cta-btn" to="/email-verification">sign up</Link>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Menu;
