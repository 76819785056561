import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TradingView = ({theme,notTransparent}) => {
    useEffect(() => {
        // Check if the script is already present
        if (!document.getElementById('tradingview-widget-script2')) {
          const script = document.createElement("script");
          script.id = 'tradingview-widget-script2';
          script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
          script.async = true;
      
          const config = {
            "symbols": [
              {
                "proName": "FOREXCOM:SPXUSD",
                "title": "S&P 500"
              },
              {
                "proName": "FOREXCOM:NSXUSD",
                "title": "US 100"
              },
              {
                "proName": "FX_IDC:EURUSD",
                "title": "EUR/USD"
              },
              {
                "proName": "BITSTAMP:BTCUSD",
                "title": "Bitcoin"
              },
              {
                "proName": "BITSTAMP:ETHUSD",
                "title": "Ethereum"
              }
            ],
            "showSymbolLogo": true,
            "colorTheme": "light",
            "isTransparent": false,
            "displayMode": "adaptive",
            "locale": "en"
          }
      
          script.innerHTML = JSON.stringify(config);
      
          const container = document.getElementById("tradingview-widget2");
          if (container) {
            container.innerHTML = ""; // Clear any existing content to prevent duplication
            container.appendChild(script);
          }
        }
      }, []);
      

  return (
    <div>
        <div className="tradingview-widget-container jejekkeeeekekekek">
      <div className="tradingview-widget-container__widget" id="tradingview-widget2"></div>
      <div className="tradingview-widget-copyright">
        <Link href="#" rel="noopener" target="_blank">
          {/* <span className="blue-text">Cryptocurrency Markets</span> */}
        </Link>{" "}
        {/* Live Updates */}
      </div>
    </div>
    </div>
  );
};

export default TradingView;
