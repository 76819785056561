import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';


function UpwardCounter() {

 const[counterOn,setCounterOn] = useState(false);

  return (
    <section className='upward-counter mt-12'>
        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
        <div className="grid-container  lg:grid lg:grid-cols-3 place-items-center">
            <div className="grid-item text-center">
                <h5>Investors</h5>
                <p>
                { counterOn && <CountUp start={0} end={100} duration={2} delay={0}/> }k+
                </p>
            </div>
            <div className="grid-item text-center">
                <h5>Active Users</h5>
                <p>
                 {counterOn && <CountUp start={0} end={500} duration={2} delay={0}/> }K+
                </p>
            </div>
            <div className="grid-item text-center">
                <h5>Followers</h5>
                <p>
                {counterOn && <CountUp start={0} end={10000} duration={2} delay={0}/>} k+
                </p>
            </div>
        </div>
        </ScrollTrigger>
    </section>
  )
}

export default UpwardCounter