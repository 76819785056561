import React, { useState } from 'react'
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from 'react-router-dom';
import LoadingSpinner from '../shared/LoadingSpinner';

function UpdateKyc() {
    const {userId} = useParams();
    const [inputVal,setInputVal] = useState("");
    const[loading,setLoading] = useState(false)

    // Handle Chnage
    const handleChange = e => {
    setInputVal(e.target.value);
    }


    const handleSubmit = async(event) => {
    event.preventDefault();

    // Simple input validation
    if (!inputVal) {
        toast.error("Please enter a value");
        return;
      }

    setLoading(true)
    const data ={
    kyc:inputVal.toLocaleLowerCase()
    }
    const url = `${process.env.REACT_APP_UPDATE_KYC}/${userId}`;
    // console.log(url)
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify(data)
    };
    try {
      const res = await fetch(url, params);
      const resObj = await res.json();
      if(!res.ok){
        throw new Error(resObj.message)
      }
      toast.success(resObj.message)
      setLoading(false)
      
    } catch (error) {
      toast.error(error.message);
      setLoading(false)
    }

    }

    
  return (
    <section className='admin-dashboard'>
        <div className="update-user-kyc">
            <h5 className='title font-bold uppercase'>Update the selected user kyc</h5>
            <div className="form-container">
                {/* React hot Toast */}
                 <Toaster position="top-center" reverseOrder={false}></Toaster>
                <form onSubmit={handleSubmit}>
                    <div className="form-field">
                        <input type="text" placeholder='Enter true or false' value={inputVal} name='kyc' onChange={handleChange}/>
                    </div>
                    <div className="form-field">
                        <button className='cta-btn'>
                            {
                            loading ? <LoadingSpinner/> : "Update KYC"
                            }
                        </button>
                    </div>
                </form>
                <div className="goback">
                <Link className='font-bold' to="/admin_tetfi/dashboard">
                go back to home
                </Link>
                </div>
            </div>
        </div>
    </section>
  )
}

export default UpdateKyc