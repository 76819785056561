import React from 'react';
import Header from '../Header/Header';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <>
    <Header/>
    <section>
     <div className="privacy-policy">
      <h4
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      >Privacy Policy</h4>
      
      <h5>1. Introduction</h5>
      <p>
        Tetfi ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By accessing or using our services, you agree to this Privacy Policy.
      </p>

      <h5>2. Information We Collect</h5>
      <p><strong>Personal Information:</strong> We may collect personal information such as your name, email address, phone number, and payment information.</p>
      <p><strong>Non-Personal Information:</strong> We may also collect non-personal information such as your browser type, device information, and usage data.</p>

      <h5>3. How We Use Your Information</h5>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, maintain, and improve our services.</li>
        <li>Process transactions and send you related information, including purchase confirmations and invoices.</li>
        <li>Communicate with you about promotions, updates, and other relevant information.</li>
        <li>Monitor and analyze trends, usage, and activities to improve our offerings.</li>
      </ul>

      <h5>4. How We Share Your Information</h5>
      <p>We may share your information with service providers, or as required by law.</p>

      <h5>5. Security of Your Information</h5>
      <p>We use reasonable administrative, technical, and physical measures to protect your information.</p>

      <h5>6. Your Privacy Rights</h5>
      <p>Depending on your location, you may have rights such as access, correction, and deletion of your personal information.</p>

      <h5>7. Changes to This Privacy Policy</h5>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>

      <h5>8. Contact Us</h5>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at <Link style={{textTransform:"initial",display:"inline-block",color:"#FFD700"}} to="mailto:info.tetfi@gmail.com">info.tetfi@gmail.com</Link></p>
    </div>
    </section>
    </>
  );
};

export default PrivacyPolicy;
