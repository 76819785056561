import React from 'react'
import DashboardHome from './DashboardHome'


const NotificationsChildren = () => {
return(
  <div className="notification-container mt-20">
    <h4>notifications</h4>
    <div className="message-box">
      <p className="message-contents">
      Congratulations! Your account has been successfully created with Tetfi. You can now explore your investment options and start your journey with us. If you have any questions or need assistance, please don’t hesitate to reach out.
      </p>
    </div>
  </div>
)
}


function Notifications() {
  return (
    <DashboardHome children={<NotificationsChildren/>}/>
  )
}

export default Notifications