
import React, {  useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Toaster, toast } from "react-hot-toast";
import LoadingSpinner from '../shared/LoadingSpinner';
import EyeIcon from '../icons/EyeIcon';


function ForgotPasswordOTP() {
const navigate =  useNavigate();
const [serchParams] = useSearchParams();
const authToken = serchParams.get("authToken")
const [loading, setLoading] = useState(false);
const [otpInput, setOtpInput] = useState(new Array(4).fill(""));
const[password,setPassword] = useState(false);
const[user,setUser] = useState({
  otp:"",
  newPassword:""
})




// useEffect(()=>{
// const getAuthEmail = JSON.parse(localStorage.getItem("authCredentials"));
// setAuthEmail(getAuthEmail?.email)
// },[])
// console.log(`Auth Email ${authEmail}`)


const handlePassword = () => {
  setPassword((prev) => !prev);
};


  // Handle Change
  const handleChange = (e,i) => {
    // for the password
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    // for otp
    setOtpInput((prev) => ([
        ...prev.map((item, index) => (index === i ? e.target.value : item)),
      ]));

      // when  you have one element in one box move to the next box
    if (e.target.value && e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
  
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
    newPassword:user.newPassword,
    otp:otpInput.join("")
    }
    const url = `${process.env.REACT_APP_RESET_PASSWORD_TOKEN}?authToken=${authToken}`;
    // console.log(otpInput.join(""))
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);

      // Go to register page after 3 seconds
      setTimeout(() => {
        navigate(`/login`);
      }, 2000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <section className="auth-section">
    <div className="form-container">
        {/* React hot Toast */}
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        
      <div className="form-header">
        <Link to="/" className="img-container">
          <img src={`/assets/tetfi.png`} alt="logo" />
        </Link>
      </div>
      <div className="form-body">
        <form onSubmit={handleSubmit}>
        <span>Enter the verification code sent to your email</span>
          <div className="form-field otp-field">
                {otpInput.map((data, i) => (
                  <input
                    key={i}
                    type="text"
                    onChange={(e) => handleChange(e, i)}
                    value={data}
                    maxLength={1}
                  />
                ))}
              </div>
              <div className="form-field">
                <label htmlFor="newPassword">New Password</label>
                <input
                type={password ? "text" : "password"}
                name="newPassword"
                id="newPassword"
                value={user.newPassword}
                placeholder="****"
                onChange={handleChange}
              />
              <span
                className={`eye-icon-container ${password ? "active" : ""}`}
                onClick={handlePassword}
              >
                <EyeIcon />
              </span>
              </div>

          <div className="form-field">
            <button>
              <span>{loading ? <LoadingSpinner /> : "submit"}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
  )
}

export default ForgotPasswordOTP;