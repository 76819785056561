import React, { useState } from "react";
import MenuBarIcon from "../icons/MenuBarIcon";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const handleToggleMenu = () => {
    setToggleMenu((prev) => !prev);
  };
  return (
    <div className="navbar-container">
      <div className="tradeview-marque">
        <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=" width="100%" height="36px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style={{border:0,margin:"0px",padding:"0px"}}> </iframe>
       </div>
      <div className="flex-container-2">
        <div className="flex-item">
          <Link to="/" className="logo-container">
            <img src={`/assets/tetfi.png`} alt="Tefti.com" />
          </Link>
        </div>
        <div className="flex-item">
          <span className="togglebar" onClick={handleToggleMenu}>
            {/* <span>Menu</span> */}
            <span>
              <MenuBarIcon />
            </span>
          </span>
        </div>
      </div>
      <nav
        className={toggleMenu ? "nav-menu active" : " nav-menu"}
        onClick={handleToggleMenu}
      >
        <Menu />
      </nav>
    </div>
  );
}

export default Navbar;
