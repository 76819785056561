import React, { useEffect, useState } from 'react'

function useCryptoAssets() {
const [assets,setAssets]  = useState({
 BTC:0,
 ETH:0,
 USDT:0,
 isLoading:false,
 serverError:null
})

useEffect(() => {
const fetchData =  async() => {
setAssets(prev => ({
...prev,
isLoading:true
}))
const url = `https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC&tsyms=USDT&fsyms=ETH&tsyms=USDT&fsyms=USDT&tsyms=USDT`
try{
const res =  await fetch(url);
const resObj =  await res.json();

if(!res.ok){
 setAssets(prev => ({
...prev,
serverError:new Error("Error fetching data..."),
isLoading:false
 }))
}

const {
    RAW:{
    BTC:{USDT:{PRICE:BTCPRICE}},
    ETH:{USDT:{PRICE:ETHPRICE}},
    USDT:{USDT:{PRICE:USDTPRICE}}
}} =  resObj;


setAssets(prev => ({
    ...prev,
    BTC:BTCPRICE,
    ETH:ETHPRICE,
    USDT:USDTPRICE,
    isLoading:false

}))

}
catch(err){
setAssets(prev => ({
...prev,
isLoading:false,
serverError:err.message
}))
}
}

// invoke the fetch data function
fetchData();
},[])


return[assets,setAssets]
}

export default useCryptoAssets;