import React from 'react';
import Header from '../Header/Header';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
   <>
   <Header/>
   <section>
   <div className="terms-and-conditions">
      <h3
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      >Terms & Conditions</h3>

      <h5>1. Introduction</h5>
      <p>
        Welcome to Tetfi. By accessing or using our platform, you agree to comply with and be bound by the following Terms & Conditions. Please review these terms carefully.
      </p>

      <h5>2. Eligibility</h5>
      <p>You must be at least 18 years old and capable of entering into legally binding agreements to use our services.</p>

      <h5>3. Account Registration</h5>
      <p>To access certain features of our platform, you must create an account. You agree to provide accurate, current, and complete information and to keep this information up to date.</p>

      <h5>4. Use of Services</h5>
      <p>You agree to use our services for lawful purposes only.</p>

      <h5>5. Investment Disclaimer</h5>
      <p>Investing in cryptocurrencies involves risk. Tetfi is not responsible for any financial losses you may incur.</p>

      <h5>6. Fees and Payments</h5>
      <p>Any fees associated with the use of our services will be clearly outlined on our platform.</p>

      <h5>7. Intellectual Property</h5>
      <p>All content on Tetfi, including text, graphics, logos, and software, is the property of Tetfi or its content suppliers and is protected by intellectual property laws.</p>

      <h5>8. Limitation of Liability</h5>
      <p>Tetfi shall not be liable for any indirect, incidental, special, or consequential damages resulting from your use of our services.</p>

      <h5>9. Termination</h5>
      <p>We reserve the right to terminate or suspend your access to our services at any time, with or without notice, for conduct that we believe violates these Terms & Conditions.</p>

      <h5>10. Governing Law</h5>
      <p>These Terms & Conditions are governed by and construed in accordance with the laws of [Your Country/State].</p>

      <h5>11. Changes to Terms</h5>
      <p>Tetfi may update these Terms & Conditions from time to time. We will notify you of any changes by posting the updated terms on our platform.</p>

      <h5>12. Contact Us</h5>
      <p>If you have any questions about these Terms & Conditions, please contact us at <Link style={{textTransform:"initial",display:"inline-block",color:"#FFD700"}} to="mailto:info.tetfi@gmail.com">info.tetfi@gmail.com</Link></p>
    </div>
   </section>
   </>
  );
};

export default TermsAndConditions;
