import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TradingViewTimelineWidget = () => {
    useEffect(() => {
        // Check if the script is already present
        if (!document.getElementById('tradingview-timeline-widget-script')) {
          const script = document.createElement("script");
          script.id = 'tradingview-timeline-widget-script';
          script.src = "https://s3.tradingview.com/external-embedding/embed-widget-timeline.js";
          script.async = true;
      
          const config = {
            feedMode: "all_symbols",
            colorTheme: "dark",
            isTransparent: true,
            displayMode: "regular",
            width: "100%",
            height: "100%",
            locale: "en",
          };
      
          script.innerHTML = JSON.stringify(config);
      
          const container = document.getElementById("tradingview-timeline-widget");
          if (container) {
            container.innerHTML = ""; // Clear any existing content to prevent duplication
            container.appendChild(script);
          }
        }
      }, []);
      
  return (
    <section>
      <h4
      data-aos="fade-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      >recent crypto news.</h4>
      <div className="tradingview-widget-container">
        <div
          id="tradingview-timeline-widget"
          className="tradingview-widget-container__widget"
          style={{ height: "400px" }}
        ></div>
        <div className="tradingview-widget-copyright">
          <Link href="#" rel="noopener noreferrer" target="_blank">
            <span className="blue-text">Daily news roundup</span>
          </Link>{" "}
          Live Updates
        </div>
      </div>
    </section>
  );
};

export default TradingViewTimelineWidget;
