import React, { useState } from "react";

import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";

function EmailVerification() {
  const navigate =  useNavigate();
  const [serchParams] = useSearchParams();
  const referralEmail = serchParams.get("referralEmail");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
  });
  // const[userCredentials,setUserCredentials] = useState({
  //   email:"",
  //   authOTP:""
  // })

  const addToLocalStorage = (data) => {
    localStorage.setItem("authCredentials", JSON.stringify(data));
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: user.email,
    };
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const url = process.env.REACT_APP_GENERATE_OTP_URL;
      const response = await fetch(url, params);
      const resObj = await response.json();
      // setUserCredentials(prev => ({...prev,email:resObj?.email,authOTP:resObj?.authOTP}))

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
      }
      
      const  authCredentials = resObj?.data?.user;
      if(authCredentials){
         // go to verify otp page after 3 seconds
        // to convert btoa(), use atob()
        addToLocalStorage(authCredentials)
        toast.success(resObj?.message);
        setLoading(false);

         setTimeout(()=>{
          navigate(`/verify-auth-otp?otp=${btoa(authCredentials?.email)}&email=${authCredentials?.email}&${referralEmail ? `referralEmail=${referralEmail}` : ""}`);
         },3000)
      }

    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <section className="auth-section">
      <div className="form-container">
          {/* React hot Toast */}
          <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="form-header">
          <Link to="/" className="img-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="form-field">
              <label htmlFor="email">
                Enter your email to get the verifcation code
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={user.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
            <div className="form-field">
              <button disabled={loading}>
                <span>{loading ? <LoadingSpinner /> : "get verification code"}</span>
              </button>
            </div>
            {/* OR */}
            <div className="option-label">
              <span>OR</span>
            </div>

            <div className="form-field">
              <Link className="auth-cta-btn" to="/login">
                login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default EmailVerification;
