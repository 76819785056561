import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "./icons/AddIcon";
import Support from "./Support";
import TradingViewWidget from "../Home/TradingView";
import InvestmentPackages from "./InvestmentPackages";
import AccountDetails from "./AccountDetails";
import CryptoConverter from "./CryptoConverter";
import TradingView from "./TradingView";
import useProfile from "../hooks/useProfile";
import useCryptoAssets from "../hooks/useCryptoAssets";
import TermsIcon from "./icons/TermsIcon";
import ReferralIcon from "./icons/ReferralIcon";
import InvestmentsIcon from "./icons/InvestmentsIcon";

function DashboardMainBody({ firstName, lastName, email, country }) {
  const [userProfile] = useProfile();
  const [showDropdown, setShowDropdown] = useState(false);
  // const[coin,setCoin] =  useState(0)


  const [{ BTC, ETH, USDT, isLoading }] = useCryptoAssets();


  // Get the user profile
  const { wallets = {}, kyc = false } = userProfile;
  const { BTC: walletBTC, ETH: walletETH, USDT: walletUSDT } = wallets;

  const convertToBTC = walletBTC * BTC;
  const convertToETH = walletETH * ETH;
  const convertToUSDT = walletUSDT * USDT;


  // Handle dropdown
  const handleDropdown = () => {
    setShowDropdown(prev => !prev);
  }




  // console.log(wallets)
  return (
    <div className="dashboard-main-body mt-20">
      <div className="widget-container">
        <TradingView />
      </div>
      <div className="flex-wrapper lg:flex lg:flex-wrap lg:justify-between mb-20">
        <div className="flex-item">
          <div className="top-container mb-2">
            <h4>
              Hello, {lastName} {firstName} !
            </h4>
            <p>Welcome back to your Dashboard, Let's get started</p>
          </div>
          <div className="bottom-container">
            <p className="mb-2">
              <strong>Email</strong> : {email}{" "}
            </p>
            {
              kyc ? (
                <p className="mb-2">
                  <strong>KYC</strong> :{" "}
                  <span className="inline-block verified">Verified</span>
                </p>
              ) : null
            }
            <p className="title mb-2 uppercase"><strong>Deposit Balance </strong> :  {` $ ${wallets?.depositWallet}`}</p>
            <p className="mb-2">
              <strong>Nationality</strong> : {country}
            </p>
          </div>
        </div>
        <div className="flex-item flex relative items-center">
          <Link className="cta-btn" to="/dashboard/invest">
            <span>invest</span>
          </Link>
          <Link className="cta-btn basis-[72%]" to="/dashboard/investments">
            <span>investment history</span>
          </Link>
          <Link className="cta-btn active" onClick={handleDropdown}>
            <span className="inline-block align-middle">
              <AddIcon />
            </span>
          </Link>
          {showDropdown ? ( 
            <div className="dropdown absolute top-[100%] lg:top-[70%]  right-4">
              <div className="balance">
                <p className="mb-4 title">Wallet Balance : {`$ ${wallets?.depositWallet}`}</p>
              </div>
              <Link to="/terms_conditions" className="flex gap-4">
                <span className="inline-block align-middle">
                  <TermsIcon />
                </span>
                terms and privacy</Link>
              <Link to="/dashboard/ref" className="flex gap-4">
                <span className="inline-block align-middle">
                  <ReferralIcon />
                </span>
                referrals</Link>
              <Link to="/dashboard/investments" className="flex gap-4">
                <span className="inline-block align-middle">
                  <InvestmentsIcon />
                </span>
                all investments</Link>
            </div>
          ) : null}
        </div>

      </div>

      {/* Investment Calculator Widget */}

      {/* Dashboard information */}
      <div className="wallet-details mb-8">
        <h4 className="title uppercase mb-8">Financial Account Details</h4>
        <div className="assets-section">
          <div className="flex-row flex-container grid md:grid-cols-3">
            <figure>
              <div className="item-header">
                <div className="img-container">
                  <img src={`/assets/bitcoin-icon.svg`} alt="ethereum icon" />
                </div>
                <span>Bitcoin</span>
              </div>
              <figcaption>
                <p className="exchange-rate" id="btc">{wallets?.BTC} <span> BTC</span></p>
                <small className="dollar-conversion" id="btc_equi">{isLoading || BTC === 0 ? "fetching balance.." : `$ ${convertToBTC.toFixed(4)}`} </small>
              </figcaption>
            </figure>
            <figure>
              <div className="item-header">
                <div className="img-container">
                  <img src={`/assets/etherum-icon.svg`} alt="ethereum icon" />
                </div>
                <span>Ethereum</span>
              </div>
              <figcaption>
                <p className="exchange-rate" id="eth">{wallets?.ETH}<span> ETH</span></p>
                <small className="dollar-conversion" id="eth_equi">{isLoading || ETH === 0 ? "fetching balance.." : `$ ${convertToETH.toFixed(4)}`}</small>
              </figcaption>
            </figure>

            <figure>
              <div className="item-header">
                <div className="img-container">
                  <img src={`/assets/tetha-btc-icon.svg`} alt="tetha icon" />
                </div>
                <span>Tether</span>
              </div>
              <figcaption>
                <p className="exchange-rate" id="usdt">{wallets?.USDT}<span> USDT</span></p>
                <small className="dollar-conversion" id="usdt_equi">{isLoading || USDT === 0 ? "fetching balance.." : `$ ${convertToUSDT.toFixed(4)}`}</small>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>

      <div className="account-details mb-20">
        <AccountDetails wallets={wallets} />
      </div>

      <div className="crypto-converter mb-20">
        <h4 className="title">Crypto Converter</h4>
        <CryptoConverter />
      </div>


      <div className="trading-view-section">
        <TradingViewWidget theme="white" notTransparent={true} />
      </div>
      <div className="investment-plans mb-8">
        <p className="title uppercase mb-4">
          investment plans
        </p>
        <p>
          At Tetfi, we offer a range of tailored investment plans designed to meet the needs of both new and experienced cryptocurrency investors. Whether you're looking to grow your portfolio with Bitcoin (BTC), explore the potential of Ethereum (ETH), or maintain stability with Tether (USDT), our plans provide the flexibility and security you need to achieve your financial goals.
        </p>
      </div>
      {/* Investment Plans Goes Here */}
      <InvestmentPackages />

      {/* Support section  */}
      <div className="support-section">
        <Support marginVal="0" />
      </div>
    </div>
  );
}

export default DashboardMainBody;
