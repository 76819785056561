import React, { useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../icons/EyeIcon";
import LoadingSpinner from "../shared/LoadingSpinner";

function AdminLogin() {
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  // Toggle Password Visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  // Handle Input Change
  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple input validation
    if (!credentials.username || !credentials.password) {
      toast.error("Please fill in both fields.");
      return;
    }

    setLoading(true);

    // Encode username and password in Base64 for Basic Auth
    const encodedCredentials = btoa(
      `${credentials.username}:${credentials.password}`
    );
    const url = process.env.REACT_APP_ADMIN_LOGIN; // Replace with your actual API endpoint

    const params = {
      method: "GET", // or POST if the endpoint requires POST
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${encodedCredentials}`, // Add Authorization header with Base64 encoded credentials
      },
    };

    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      if (!response.ok) {
        throw new Error(resObj.message);
      }


    // On successful login, store authentication in localStorage or sessionStorage
     localStorage.setItem("isAuthenticated", true); // You could also store a token here instead

      toast.success(resObj.message);
      setTimeout(() => {
        navigate("/admin_tetfi/dashboard"); // Redirect to the admin dashboard on success
      }, 3000);
    } catch (err) {
      toast.error("Invalid Credentials");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="auth-section">
      <div className="form-container">
        <Toaster position="top-center" reverseOrder={false} />

        <div className="form-header">
          <Link to="/" className="img-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="form-header">
              <h4 className="text-center">Admin Login</h4>
            </div>
            <div className="form-field">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                value={credentials.username}
                placeholder="Username"
                onChange={handleChange}
              />
            </div>
            <div className="form-field">
              <label htmlFor="password">Password</label>
              <input
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                id="password"
                value={credentials.password}
                placeholder="Password"
                onChange={handleChange}
              />
              <span
                className={`eye-icon-container ${isPasswordVisible ? "active" : ""}`}
                onClick={handlePasswordVisibility}
              >
                <EyeIcon />
              </span>
            </div>

            <div className="form-field">
              <button type="submit" disabled={loading}>
                {loading ? <LoadingSpinner /> : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AdminLogin;
