import React, { useEffect } from 'react'

function RedirectHandler() {
  useEffect(() => {
    const currentHost = window.location.hostname;
    // console.log(currentHost)
    if (currentHost === "www.tetfi.com") {
      // Redirect to the new domain
      window.location.href = "https://www.tetfi.ng";
    }
  }, []);

  return null;
}

export default RedirectHandler


