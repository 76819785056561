import React from 'react'
import CloseIcon from '../icons/CloseIcon'
import { Link } from 'react-router-dom'
import CheckIcon from '../icons/CheckIcon'

function Packages() {
  return (
    <section className='affordable-packages' id='investment-plans'>
        {/* <h3>Choose your Preferable Plans for Investement.</h3> */}
        <h3
       data-aos="fade-right"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="500" 
        >Investement Plans.</h3>
        <p className='mb-8'>We offer a diverse range of investment plans for you to choose from. Select the option below that best suits your needs.</p>
        {/* <CloseIcon/> */}
        <div className="cards card-group lg:grid lg:grid-cols-3">
            <div
            data-aos="zoom-in-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="card-container">
                <figure className="card flex justify-center flex-col items-center">
                    <div className="card-header text-center">
                        <h4 className='mb-8'>Bronze</h4>
                        <p className='mb-4'> <span className='profit font-bold text-xl lg:text-2xl'>2% </span><sup className='text-sm'>/ Daily</sup></p>
                    </div>
                    <div className="card-body">
                        <ul>
                            <li><span className='check-icon'><CheckIcon/></span>minimum deposit : <span className='amount ml-2'>$200</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>maximum deposit : <span className='amount ml-2'>$5,000</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>monthly profit : <span className='amount ml-2'>60%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>daily profit : <span className='amount ml-2'>2%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Duration : <span className='amount ml-2'>2 Weeks</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Referral Bonus : <span className='amount ml-2'>5%</span></li>
                            <li><span className='close-icon'><CloseIcon/></span>monthly bonus : <span className='amount ml-2'>N/A</span></li>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <div className="btn-container">
                            <Link to="/email-verification" className="cta-btn">
                             get Started
                            </Link>
                        </div>
                    </div>
                </figure>
            </div>
            <div 
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="card-container">
                <figure className="card flex justify-center flex-col items-center">
                    <div className="card-header text-center">
                        <h4 className='mb-8'>Silver</h4>
                        <p className='mb-4'> <span className='profit font-bold text-xl lg:text-2xl'>2.5% </span><sup className='text-sm'>/ Daily</sup></p>
                    </div>
                    <div className="card-body">
                        <ul>
                            <li><span className='check-icon'><CheckIcon/></span>minimum deposit : <span className='amount ml-2'>$5,000</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>maximum deposit : <span className='amount ml-2'>$10,000</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>monthly profit : <span className='amount ml-2'>75%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>daily profit : <span className='amount ml-2'>2.5%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Duration : <span className='amount ml-2'>3 Weeks</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Referral Bonus : <span className='amount ml-2'>5%</span></li>
                            <li><span className='close-icon'><CloseIcon/></span>monthly bonus : <span className='amount ml-2'>N/A</span></li>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <div className="btn-container">
                            <Link to="/email-verification" className="cta-btn">
                             get Started
                            </Link>
                        </div>
                    </div>
                </figure>
            </div>
            <div 
            data-aos="zoom-in-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            className="card-container">
                <figure className="card flex justify-center flex-col items-center">
                    <div className="card-header text-center">
                        <h4 className='mb-8'>Gold</h4>
                        <p className='mb-4'> <span className='profit font-bold text-xl lg:text-2xl'>3% </span><sup className='text-sm'>/ Daily</sup></p>
                    </div>
                    <div className="card-body">
                        <ul>
                            <li><span className='check-icon'><CheckIcon/></span>minimum deposit : <span className='amount ml-2'>$10,000</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>maximum deposit : <span className='amount ml-2'>unlimited</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>monthly profit : <span className='amount ml-2'>90%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>daily profit : <span className='amount ml-2'>3%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Duration : <span className='amount ml-2'>1 Month</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>Referral Bonus : <span className='amount ml-2'>5%</span></li>
                            <li><span className='check-icon'><CheckIcon/></span>monthly bonus : <span className='amount ml-2'>3%</span></li>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <div className="btn-container">
                            <Link to="/email-verification" className="cta-btn">
                             get Started
                            </Link>
                        </div>
                    </div>
                </figure>
            </div>
        </div>
    </section>
  )
}

export default Packages